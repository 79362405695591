import DefaultLayoutStore, { IDefaultLayoutSetings } from 'layouts/DefaultLayout.store';
import { FC, useEffect } from 'react';

interface IHoc {
  children: JSX.Element;
  settings: IDefaultLayoutSetings;
}

export const Hoc: FC<IHoc> = ({ children, settings }) => {
  const { setSettings, reset } = DefaultLayoutStore.useStore((state) => state);
  useEffect(() => {
    setSettings(settings);
    return () => reset();
    // eslint-disable-next-line
  }, [JSON.stringify(settings)]);
  return children;
};
