import { IconButton } from '@mui/material';
import { theme } from 'core/theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { FooterWrapper } from './style';

const footerMenuItems = [
  { icon: HomeOutlinedIcon, type: 'HOME', link: '/' },
  { icon: LocalMallOutlinedIcon, type: 'MARKETPLACE', link: '/spending-tokens/marketplace' },
];

export const FooterContent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const renderIconsBgColor = (type: string, property: 'COLOR' | 'BGCOLOR') => {
    const isWhite = (paths: string | string[]) =>
      paths.includes(pathname) ? theme.palette.commonPalette.white : theme.palette.primary.main;
    const isGradient = (paths: string | string[]) =>
      paths.includes(pathname) ? theme.palette.gradient.newButton : '';

    const iconPaths: { [key: string]: string } = {
      HOME: property === 'COLOR' ? isGradient(['/']) : isWhite(['/']),
      VOTE:
        property === 'COLOR'
          ? isGradient(['/initiatives', '/initiative'])
          : isWhite(['/initiatives', '/initiative']),
      MARKETPLACE:
        property === 'COLOR'
          ? isGradient(['/spending-tokens', '/spending-tokens/marketplace', '/transfer'])
          : isWhite(['/spending-tokens', '/spending-tokens/marketplace', '/transfer']),
    };

    return iconPaths[type];
  };

  return (
    <FooterWrapper>
      {footerMenuItems.map((values, index) => (
        <IconButton
          key={index}
          onClick={() => navigate(values.link)}
          sx={{
            color: renderIconsBgColor(values.type, 'BGCOLOR'),
            background: renderIconsBgColor(values.type, 'COLOR'),
          }}
        >
          <values.icon sx={{ fontSize: theme.spacing(3) }} />
        </IconButton>
      ))}
    </FooterWrapper>
  );
};

FooterContent.displayName = 'FooterContent';
