import { IPaginatedContactParams } from '../interfaces/accountApi';
import { IPaginatedListParams, IQueryParams, ITransactionOptions } from '../interfaces/serviceApi';
import { IPaginatedListParams as IPaginatedListParamsAcrossCompanies } from '../interfaces/acrossCompanies';

//TODO do it differently (Pick)
const queryBuilder = (
  options:
    | IPaginatedListParams
    | IPaginatedListParamsAcrossCompanies
    | IPaginatedContactParams
    | ITransactionOptions
    | Pick<IPaginatedListParams, 'limit'>
    | Pick<IQueryParams, 'status'>
    | undefined
) => {
  const params = [];
  let index = 0;

  for (const property in options) {
    type optionsKey = keyof typeof options;
    params.push(
      index === 0
        ? `?${property}=${options[property as optionsKey]}`
        : `&${property}=${options[property as optionsKey]}`
    );
    index++;
  }

  return params.join('');
};

export { queryBuilder };
