import { RequestStatusEnum } from '../core/enums/stateEnum';
import { IBalance, IBalanceAdmin, IBalanceUser } from '../core/interfaces/balance';
import { IBalanceOptions } from '../core/interfaces/serviceApi';
import { IResponseError } from '../core/interfaces/status';
import { IPatchTokenAdminPayload } from '../core/interfaces/token';
import {
  fetchAllTokens,
  fetchTokenCollection,
  fetchTokenCompanyCollection,
  patchToken,
} from '../services/tokenApi';
import { RootState } from './rootReducer';
import { setSnackBar } from './snackbarSlice';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import request from 'axios';
import i18next from 'i18next';

interface ITokenState {
  items: IBalance[] | IBalanceAdmin[] | IBalanceUser[];
  currentPage: number;
  total: number;
  loading: RequestStatusEnum;
  error: IResponseError;
  selectedTokenIndex: number;
}

export const fetchTokenCollectionThunk = createAsyncThunk(
  'token/fetchTokenCollectionThunk',
  async (options: IBalanceOptions, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchTokenCollection(options);

      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const fetchAllTokensThunk = createAsyncThunk(
  'token/fetchAllTokensThunk',
  async (options: IBalanceOptions, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAllTokens(options);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const fetchTokenCompanyCollectionThunk = createAsyncThunk(
  'token/fetchTokenCompanyCollectionThunk',
  async ({ options }: { options: IBalanceOptions }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchTokenCompanyCollection(options);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const patchTokenThunk = createAsyncThunk(
  'token/patchTokenThunk',
  async (
    { tokenId, data }: { tokenId: string; data: IPatchTokenAdminPayload },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await patchToken(tokenId, data);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const initialState: ITokenState = {
  items: [],
  currentPage: 0,
  total: 0,
  loading: RequestStatusEnum.IDLE,
  error: {} as IResponseError,
  selectedTokenIndex: 0,
};

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    resetTokens: (state: ITokenState) => {
      state.items = [];
      state.selectedTokenIndex = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTokenCollectionThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(fetchTokenCollectionThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      if (state.currentPage === 0) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }
      return state;
    });
    builder.addCase(fetchTokenCollectionThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
    builder.addCase(fetchAllTokensThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(fetchAllTokensThunk.fulfilled, (state, action) => {
      state.currentPage = action.payload.currentPage;
      if (state.currentPage === 0) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }
      state.total = action.payload.total;
      state.loading = RequestStatusEnum.FULFILLED;
      return state;
    });
    builder.addCase(fetchAllTokensThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
    builder.addCase(fetchTokenCompanyCollectionThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(fetchTokenCompanyCollectionThunk.fulfilled, (state, action) => {
      state.currentPage = action.payload.currentPage;
      if (state.currentPage === 0) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }
      state.total = action.payload.total;
      state.loading = RequestStatusEnum.FULFILLED;
      return state;
    });
    builder.addCase(fetchTokenCompanyCollectionThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
    builder.addCase(patchTokenThunk.fulfilled, (state, action) => {
      state.items = state.items.map((item, index) => {
        if (item.tokenId === action.payload.tokenId) {
          state.selectedTokenIndex = index;
          return action.payload;
        }
        return item;
      });
    });
    builder.addCase(patchTokenThunk.rejected, (state, error) => {
      state.error = error.payload as IResponseError;
      return state;
    });
  },
});
export const { resetTokens } = tokenSlice.actions;
export const selectTokens = (state: RootState) => state.tokenReducer;

export default tokenSlice.reducer;
