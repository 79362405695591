import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

interface AnimationState {
  animate: boolean | null;
}

const initialState: AnimationState = {
  animate: null,
};

const animationSlice = createSlice({
  name: 'animation',
  initialState,
  reducers: {
    setAnimate: (state, action: PayloadAction<boolean | null>) => {
      state.animate = action.payload;
    },
  },
});

export const { setAnimate } = animationSlice.actions;

export const selectAnimate = (state: RootState) => state.animationReducer;

export default animationSlice.reducer;
