import axiosInstance from '../core/interceptors/authInterceptor';
import {
  IEngagedActionPayload,
  IEngagedActionsOptions,
  IEngagedActionForm,
  IPaginatedListParams,
  IEngagedActionsByCompanyOptions,
  IRwardedEngagedActionPayload,
} from '../core/interfaces/acrossCompanies';
import { queryBuilder } from '../core/helpers/apiHelpers';
import { IAccountActivatedStatus } from '../core/enums/accountEnum';
import { engagedActionFavoriteStatusEnum } from '../core/enums/sortAcrossCompaniesEnum';
import { IAccountOptions } from '../core/interfaces/accountApi';
import { IRecognizeActionPayload } from '../core/interfaces/participateToRecognizeActions';

const postActionValid = async (data: IEngagedActionForm) => {
  return await axiosInstance.post(`/action/declaration`, {
    categoryId: data.category?.id,
    valueFr: data.valueFr,
    valueEn: data.valueEn,
  });
};

const patchAction = async (action: IEngagedActionPayload) => {
  const { actionId, ...rest } = action;
  return await axiosInstance.patch(`/action/declaration/${actionId}`, {
    ...(rest && { ...rest }),
  });
};

const fetchEngagedActionsByCompany = async (
  companyId: number,
  options: IEngagedActionsByCompanyOptions
) => {
  const queryOptions: IPaginatedListParams = {
    page: options.page,
    sort: options.sort,
    sortValue: options.sortValue,
    limit: options.limit,
  };
  const queryParams = queryBuilder(queryOptions);
  return await axiosInstance.post(`/incentive/${companyId}/reward/actions/${queryParams}`, {
    query: options.query,
    categoryIds: options.categories,
    favourite: options.favourite?.map((f) =>
      f === engagedActionFavoriteStatusEnum.FAVORITE ? true : false
    ),
    enabled: options.enabled?.map((f) => (f === IAccountActivatedStatus.ACTIVE ? true : false)),
  });
};

const patchEngagedActionByCompany = async (
  companyId: number,
  action: Partial<IRwardedEngagedActionPayload>
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { actionId, enabled, ...rest } = action;
  return await axiosInstance.patch(`/incentive/${companyId}/reward/${actionId}`, {
    ...(rest && { ...rest }),
  });
};

const disableEngagedActionByCompany = async (
  companyId: number,
  action: Partial<IRwardedEngagedActionPayload>
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { actionId, enabled, ...rest } = action;
  return await axiosInstance.patch(`/incentive/${companyId}/disable/${actionId}`, {
    enabled,
  });
};

const fetchAllActions = async (options: IEngagedActionsOptions) => {
  const queryOptions: IPaginatedListParams = {
    page: options.page,
    sort: options.sort,
    sortValue: options.sortValue,
    limit: options.limit,
  };
  const queryParams = queryBuilder(queryOptions);

  return await axiosInstance.post(`/action/declaration/listActions/${queryParams}`, {
    status: options.status,
    companyIds: options.companyIds,
    query: options.query,
  });
};

const fetchValidActionsByCompany = async (options: IAccountOptions, favourite: boolean | null) => {
  const queryParams = queryBuilder(options?.queryParams);
  return await axiosInstance.post(
    `/action/declaration/${options?.companyId}/user/listActions/${queryParams}`,
    {
      query: options?.query,
      favourite,
      status: 'VALID',
    }
  );
};

const getAmountToEarn = async (companyId: number) => {
  return await axiosInstance.get(`/company/${companyId}/declaration-reward`);
};

const proposeAnAction = async (payload: IRecognizeActionPayload) => {
  return await axiosInstance.post(`/endorsement/create`, payload);
};

const fetchDeclarationRewardToken = async (companyId: number) => {
  return await axiosInstance.get(`/action/declaration/${companyId}/declarationRewardToken`);
};

const patchDeclarationRewardToken = async (
  companyId: number,
  action: Partial<IRwardedEngagedActionPayload>
) => {
  return await axiosInstance.patch(`/action/declaration/${companyId}/declarationRewardToken`, {
    ...action?.rewardedToken,
  });
};

export {
  postActionValid,
  patchAction,
  fetchAllActions,
  fetchEngagedActionsByCompany,
  patchEngagedActionByCompany,
  fetchValidActionsByCompany,
  getAmountToEarn,
  proposeAnAction,
  fetchDeclarationRewardToken,
  patchDeclarationRewardToken,
  disableEngagedActionByCompany,
};
