import React from 'react';

interface NavigationContextProps {
  menuIsShown: (pathname: string) => boolean;
}

const defaultNavigationBehavior: NavigationContextProps = {
  menuIsShown: () => true,
};

const NavigationContext = React.createContext(defaultNavigationBehavior);

export default NavigationContext;
