import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store, { useAppDispatch } from 'state/store';
import { excludedPathnames } from 'core/constants/excludedPathnames';
import { RequestStatusEnum } from 'core/enums/stateEnum';
import { Box, CssBaseline, ThemeProvider, Typography } from '@mui/material';
import NavigationProvider from 'core/contexts/navigationProvider/NavigationProvider';
import { Routes } from 'core/routes/Routes';
import './App.css';
import { theme } from 'core/theme';
import { useErrorHandler } from 'core/interceptors/authInterceptorErrorsHandler';
import { useTranslation } from 'react-i18next';
import { setSnackBar } from 'state/snackbarSlice';
import 'i18nConfig';
import SnackBar from 'components/SnackBar';
import PopUp from 'components/popUp/PopUp';
import PermissionProvider from 'core/contexts/permissionProvider/PermissionProvider';
import { AccountRoleEnum } from 'core/enums/accountEnum';
import { auth0TokenOptions } from 'core/helpers/authHelpers';
import { fetchAccountThunk, selectAccount } from 'state/accountSlice';
import { addResourceBundle } from 'i18nConfig';
import i18next from 'i18next';
import NoAffiliated from 'pages/noAffiliated/NoAffiliated';
import { ErrorBoundary } from 'react-error-boundary';
import { SplashScreen } from 'components/SplashScreen';
import * as Sentry from '@sentry/react';

interface FallbackRenderProps {
  error: { message: string };
  resetErrorBoundary: () => void;
}

const FallbackRender: React.FC<FallbackRenderProps> = ({ error }) => {
  return (
    <Box role="alert">
      <Typography variant="h3">Something went wrong</Typography>
      <Typography variant="h4">{error.message}</Typography>
    </Box>
  );
};

function App() {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { isAuthenticated, getAccessTokenSilently, isLoading, loginWithRedirect, logout, user } =
    useAuth0();

  const { account, loading, updatingStatus, error } = useSelector(selectAccount);
  const { role, permissions } = account;
  const { error: apiError } = useErrorHandler((state) => state);

  useEffect(() => {
    const hasAWafError403 = apiError && typeof apiError['response']['headers'] === 'undefined';
    if (hasAWafError403) {
      dispatch(
        setSnackBar({
          open: true,
          type: 'error',
          message: t('COMMON.SNACKBAR_MESSAGE.ERROR_403'),
        })
      );
      useErrorHandler.setState({ error: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError]);

  useEffect(() => {
    const storeAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently(auth0TokenOptions);
        const accessTokenObject = JSON.parse(JSON.stringify(accessToken));

        if (isAuthenticated) {
          const accessTokenObjectWithExpirationDate = {
            ...accessTokenObject,
            expiration_date: Date.now() + accessTokenObject.expires_in,
          };

          localStorage.setItem('accessToken', JSON.stringify(accessTokenObjectWithExpirationDate));
          await dispatch(fetchAccountThunk()).then((e) => {
            const language = Object(e.payload).language;
            const lng = language.length ? language : 'en';
            if (typeof language !== 'undefined') addResourceBundle(lng);
          });
        }
      } catch (error) {
        loginWithRedirect();
      }
    };

    storeAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    const state = store.getState();
    if (
      state.accountReducer.loading !== RequestStatusEnum.FULFILLED ||
      role === AccountRoleEnum.SUPPLIER
    ) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (
    isLoading ||
    loading !== RequestStatusEnum.FULFILLED ||
    updatingStatus === RequestStatusEnum.REJECTED
  ) {
    if (error?.errorCode === 711) {
      return (
        <ThemeProvider theme={theme}>
          <NoAffiliated />
          <PopUp
            title={t('UNAFILLIATED_USER.TITLE')}
            message={t('UNAFILLIATED_USER.LEGEND', {
              email: user?.email,
            })}
            submitText={t('UNAFILLIATED_USER.SUBMIT_TEXT')}
            open={true}
            onSubmit={logout}
          />
        </ThemeProvider>
      );
    }
    return <SplashScreen />;
  }
  const { resolvedLanguage } = i18next;

  const languageIsResolved = typeof resolvedLanguage !== 'undefined';

  if (languageIsResolved) {
    Sentry.setUser({ ...user });
    Sentry.setTag('is_from_teams', false);
    Sentry.setTag('email', user?.email);
    Sentry.setTag('company_id', user?.company?.companyId);
    Sentry.setTag('role', user?.role);
  }

  return languageIsResolved ? (
    <ErrorBoundary fallbackRender={FallbackRender}>
      <ThemeProvider theme={theme}>
        <PermissionProvider accountPermissions={permissions ?? []}>
          <NavigationProvider excludedPathnames={excludedPathnames}>
            <CssBaseline />
            <Routes />
            <SnackBar />
          </NavigationProvider>
        </PermissionProvider>
      </ThemeProvider>
    </ErrorBoundary>
  ) : (
    <SplashScreen />
  );
}

export default App;
