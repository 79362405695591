import { useMediaQuery, SpeedDial, Box } from '@mui/material';
import { PermissionEnum } from 'core/enums/accountEnum';
import { isSubset } from 'core/helpers/arrayHelpers';
import { theme } from 'core/theme';
import { DefaultLayout } from 'layouts/DefaultLayout';
import DefaultLayoutStore from 'layouts/DefaultLayout.store';
import ErrorCompanyNotKnown from 'pages/errorPages/ErrorCompanyNotKnown';
import ErrorDeniedPage from 'pages/errorPages/ErrorDeniedPage';
import ErrorRevoked from 'pages/errorPages/ErrorRevoked';
import { memo, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useRoutes, Navigate, useNavigate } from 'react-router-dom';
import { selectAccount } from 'state/accountSlice';
import { ScreensTeams } from './Screens';
import TeamsProtectedRoute from './routesProtections/TeamsProtectedRoute';
import Group from 'assets/images/Group.svg';

import { Hoc } from './Hoc';

const TeamsRoutes = () => {
  const { settings } = DefaultLayoutStore.useStore((state) => state);
  const { account } = useSelector(selectAccount);

  const navigate = useNavigate();

  const displayNoneUpMdStyle = {
    display: useMediaQuery(theme.breakpoints.up('md')) ? 'none' : 'inherit',
  };

  const displaySpeedDial = isSubset(account.permissions, [PermissionEnum.RECOGNITION]);

  const MobileSpeedDial = memo(() =>
    displaySpeedDial ? (
      <SpeedDial
        ariaLabel="SpeedDial"
        sx={{ position: 'fixed', bottom: 50, right: 16, zIndex: 1300, ...displayNoneUpMdStyle }}
        icon={<img src={Group} />}
        onClick={() => navigate(ScreensTeams.PeerPecognition.path)}
        FabProps={{
          sx: {
            background: 'linear-gradient(180deg, #9C87EB 0%, #532BE3 100%)',
            boxShadow: '0px 4px 4px 0px #6C44FF4D',
          },
        }}
      ></SpeedDial>
    ) : null
  );
  MobileSpeedDial.displayName = 'MobileSpeedDial';

  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: `${ScreensTeams.Happening.path}/:challengeId?`,
          element: (
            <TeamsProtectedRoute>
              <ScreensTeams.Happening.Component />
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.TeamsWall.path,
          element: (
            <TeamsProtectedRoute>
              <MobileSpeedDial />
              <ScreensTeams.TeamsWall.Component />
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.Unity.path,
          element: (
            <Hoc
              key={'company_advantages_id'}
              settings={{
                ...settings,
                hasRightSideBarContent: true,
                RightSideBarContent: () => (
                  <Box sx={{ height: '100%', width: '100%', background: 'red' }}>UNITY</Box>
                ),
              }}
            >
              <ScreensTeams.Unity.Component />
            </Hoc>
          ),
        },
        {
          path: ScreensTeams.PeerPecognition.path,
          element: (
            <TeamsProtectedRoute>
              <ScreensTeams.PeerPecognition.Component />
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.PostIdDetails.path,
          element: (
            <TeamsProtectedRoute>
              <ScreensTeams.TeamsWall.Component />
              <ScreensTeams.PostIdDetails.Component />
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.PostIdDetailsStandAlone.path,
          element: (
            <TeamsProtectedRoute>
              <ScreensTeams.PostIdDetailsStandAlone.Component />
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.TeamsSpending.path,
          element: (
            <TeamsProtectedRoute>
              <ScreensTeams.TeamsSpending.Component options={{ defaultTab: 'marketplace' }} />
            </TeamsProtectedRoute>
          ),
          children: [
            {
              path: ScreensTeams.Marketplace.path,
              element: (
                <TeamsProtectedRoute>
                  <ScreensTeams.Marketplace.Component />
                </TeamsProtectedRoute>
              ),
            },
            {
              path: ScreensTeams.MyCollection.path,
              element: (
                <TeamsProtectedRoute>
                  <ScreensTeams.MyCollection.Component />
                </TeamsProtectedRoute>
              ),
            },
            {
              path: ScreensTeams.MyCollectionTokenId.path,
              element: (
                <TeamsProtectedRoute>
                  <ScreensTeams.MyCollectionTokenId.Component />
                </TeamsProtectedRoute>
              ),
            },
            {
              path: `${ScreensTeams.Happening.path}/:challengeId?`,
              element: (
                <TeamsProtectedRoute>
                  <ScreensTeams.Happening.Component />
                </TeamsProtectedRoute>
              ),
            },
            {
              path: ScreensTeams.HappeningInProgress.path,
              element: (
                <TeamsProtectedRoute>
                  <ScreensTeams.HappeningInProgress.Component />
                </TeamsProtectedRoute>
              ),
            },
            {
              path: ScreensTeams.HappeningClosed.path,
              element: (
                <TeamsProtectedRoute>
                  <ScreensTeams.HappeningClosed.Component />
                </TeamsProtectedRoute>
              ),
            },
          ],
        },
        {
          path: ScreensTeams.TeamsRecognition.path,
          element: (
            <TeamsProtectedRoute>
              <Hoc
                key={'teams_recognition'}
                settings={{
                  ...settings,
                  hasRightSideBarContent: false,
                }}
              >
                <ScreensTeams.TeamsRecognition.Component />
              </Hoc>
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.Charities.path,
          element: (
            <TeamsProtectedRoute>
              <ScreensTeams.Charities.Component />
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.CompanyAdvantages.path,
          element: (
            <TeamsProtectedRoute>
              <ScreensTeams.CompanyAdvantages.Component />
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.CompanyAdvantagesId.path,
          element: (
            <TeamsProtectedRoute>
              <ScreensTeams.CompanyAdvantagesId.Component />
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.InitiativeParticipateChallengeCandidateId.path,
          element: (
            <TeamsProtectedRoute>
              <Hoc
                key={'challenge_candidate'}
                settings={{
                  ...settings,
                  hasRightSideBarContent: false,
                  RightSideBarContent: () => null,
                }}
              >
                <ScreensTeams.InitiativeParticipateChallengeCandidateId.Component />
              </Hoc>
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.InitiativeParticipateChallengeProjectId.path,
          element: (
            <TeamsProtectedRoute>
              <Hoc
                key={'challenge_project'}
                settings={{
                  ...settings,
                  hasRightSideBarContent: false,
                  RightSideBarContent: () => null,
                }}
              >
                <ScreensTeams.InitiativeParticipateChallengeProjectId.Component />
              </Hoc>
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.InitiativeResultId.path,
          element: (
            <TeamsProtectedRoute>
              <Hoc
                key={'initiative_result'}
                settings={{
                  ...settings,
                  hasRightSideBarContent: false,
                  RightSideBarContent: () => null,
                }}
              >
                <ScreensTeams.InitiativeResultId.Component />
              </Hoc>
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.InitiativeResultProjectId.path,
          element: (
            <TeamsProtectedRoute>
              <Hoc
                key={'initiative_result_project'}
                settings={{
                  ...settings,
                  hasRightSideBarContent: false,
                  RightSideBarContent: () => null,
                }}
              >
                <ScreensTeams.InitiativeResultProjectId.Component />
              </Hoc>
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.UserUaffleId.path,
          element: (
            <TeamsProtectedRoute>
              <Hoc
                key={'user_raffle'}
                settings={{
                  ...settings,
                  hasRightSideBarContent: false,
                  RightSideBarContent: () => null,
                }}
              >
                <ScreensTeams.UserUaffleId.Component />
              </Hoc>
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.MarketplaceBrands.path,
          element: (
            <TeamsProtectedRoute>
              <Hoc
                key={'marketplace_brands'}
                settings={{
                  ...settings,
                  hasRightSideBarContent: false,
                  RightSideBarContent: () => null,
                }}
              >
                <ScreensTeams.MarketplaceBrands.Component />
              </Hoc>
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.MarketplaceBrandsId.path,
          element: (
            <TeamsProtectedRoute>
              <Hoc
                key={'marketplace_brands_id'}
                settings={{
                  ...settings,
                  hasRightSideBarContent: false,
                  RightSideBarContent: () => null,
                }}
              >
                <ScreensTeams.MarketplaceBrandsId.Component />
              </Hoc>
            </TeamsProtectedRoute>
          ),
        },
        {
          path: ScreensTeams.PostIdDetails.path,
          element: (
            <TeamsProtectedRoute>
              <ScreensTeams.TeamsWall.Component />
              <ScreensTeams.PostIdDetails.Component />
            </TeamsProtectedRoute>
          ),
        },
        {
          path: '/teams-support',
          element: (
            <TeamsProtectedRoute>
              <Navigate to="/notFound" replace />
            </TeamsProtectedRoute>
          ),
        },
        {
          path: '*',
          element: (
            <TeamsProtectedRoute>
              <Navigate to="/notFound" replace />
            </TeamsProtectedRoute>
          ),
        },
      ],
      element: (
        <Suspense>
          <DefaultLayout />
        </Suspense>
      ),
    },
    { path: '/denied', element: <ErrorDeniedPage /> },
    { path: '/error-company-not-known', element: <ErrorCompanyNotKnown /> },
    { path: '/revoked', element: <ErrorRevoked /> },
  ]);
};
export { TeamsRoutes };
