import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { AccountRoleEnum } from '../../enums/accountEnum';

interface ProtectedRouteProps extends Omit<RouteProps, 'component'> {
  privilege?: AccountRoleEnum | AccountRoleEnum[]; //? does the current user has the right role to access the route
  role?: AccountRoleEnum | null; //? user role
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  children?: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, privilege, role }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  if (privilege) {
    if (typeof privilege === 'string') {
      return privilege === role ? children : <Navigate to="/notFound" />;
    }

    return privilege.includes(role as AccountRoleEnum) ? children : <Navigate to="/notFound" />;
  }

  return children;
};

export default ProtectedRoute;
