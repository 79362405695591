import { Avatar, Stack, Typography, useTheme } from '@mui/material';
import { CSSProperties, FC } from 'react';

interface UserAvatarProps {
  styleOverrides?: CSSProperties;
  firstName: string;
  lastName: string;
  size: number;
  pictureUrl: string | undefined | null;
  borderRadius?: number;
  fontSize?: number;
}

const UserAvatar: FC<UserAvatarProps> = ({
  styleOverrides,
  firstName,
  lastName,
  size,
  pictureUrl,
  borderRadius,
  fontSize,
}) => {
  const theme = useTheme();
  const initials = `${firstName?.substring(0, 1)}${lastName?.substring(0, 1)}`;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        ...styleOverrides,
        width: `${size}px`,
        height: `${size}px`,
        background: pictureUrl ? 'transparent' : theme.palette.error.dark,
        borderRadius: '50%',
        border: `${borderRadius}px solid white`,
      }}
    >
      {pictureUrl ? (
        <Avatar src={pictureUrl} sx={{ width: '100%', height: '100%' }} />
      ) : (
        <Typography
          sx={{
            fontSize: fontSize ? `${fontSize}rem` : '4rem',
            color: theme.palette.commonPalette.whiteFont,
          }}
        >
          {initials.toUpperCase()}
        </Typography>
      )}
    </Stack>
  );
};

export default UserAvatar;
