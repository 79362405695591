import { styled, AppBar, useMediaQuery, Box } from '@mui/material';

export const NavBarWrapper = styled(AppBar)(({ theme }) => ({
  '& .NavBarWrapper__wrapper': {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: `calc(${theme.breakpoints.values.xl}px - ${theme.spacing(6)})`,
    margin: '0 auto',
    width: '100%',
    '& .NavBarWrapper__content': {
      width: '100%',
      alignItems: 'center',
      display: 'flex',
      gap: theme.spacing(3),
      '& .NavBarWrapper__content_links': {
        alignItems: 'center',
        display: useMediaQuery(theme.breakpoints.down('md')) ? 'none' : 'flex',
        gap: theme.spacing(3),
      },
      '& a': {
        textAlign: useMediaQuery(theme.breakpoints.down('md')) ? 'center' : 'left',
        fontSize: theme.spacing(2),
        textDecoration: 'none',
        color: 'var(--DJOB-FONT-Dark-Font, #100066)',
        position: 'relative',
        '&.NavBarLogo': {
          '&:after': {
            content: '""',
            borderBottom: '0',
          },
          '&:hover': {
            '&:after': {
              content: '""',
              borderBottom: '0',
            },
          },
        },
        '&:hover': {
          color: 'var(--new_djob-pink-raspberry, #D23979)',
          '&:after': {
            content: '""',
            display: 'block',
            borderBottom: '2px solid var(--new_djob-pink-raspberry, #D23979)',
            width: '100%',
            height: '1px',
            position: 'absolute',
            bottom: '-12px',
          },
        },
        '&.active': {
          color: 'var(--new_djob-pink-raspberry, #D23979)',
          fontWeight: '800',
          '&:after': {
            content: '""',
            display: 'block',
            borderBottom: '2px solid var(--new_djob-pink-raspberry, #D23979)',
            width: '100%',
            height: '1px',
            position: 'relative',
            bottom: '-12px',
          },
          '&.NavBarLogo': {
            '&:after': {
              content: '""',
              borderBottom: '0',
            },
            '&:hover': {
              '&:after': {
                content: '""',
                borderBottom: '0',
              },
            },
          },
        },
        fontWeight: '300',
        '& svg': {
          fontSize: theme.spacing(5),
        },
      },
    },
  },
  backgroundColor: '#F8F7FE',
  borderRadius: '0px',
  boxShadow: '0px 0px 0px -1px rgba(2, 36, 62, 0)',
  paddingInline: theme.spacing(useMediaQuery(theme.breakpoints.down('md')) ? 2 : 3),

  position: 'sticky',
  '& .profileIconButton': {},
}));

export const FooterWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(3),
  '& button': {
    fontSize: '2.4rem',
    '&:hover': {
      background: theme.palette.gradient.newButton,
      color: theme.palette.commonPalette.white,
    },
    borderRadius: theme.spacing(1),
  },
}));
