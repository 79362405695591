import { Dialog, DialogContent, Stack, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import TurnOn from 'assets/images/TurnOn.png';
import { theme } from 'core/theme';
import { Trans, useTranslation } from 'react-i18next';

const ErrorRevoked = () => {
  const [t] = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return !isMobile ? (
    <Dialog
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: theme.palette.purple.backdropPurple,
          backdropFilter: 'blur(4px)',
        },
        '& .MuiDialogContent-root': { padding: '0' },
        '& .MuiDialog-paper': {
          borderRadius: '8px',
        },
      }}
      open={true}
    >
      <DialogContent sx={{ height: '319px', borderRadius: '8px' }}>
        <Stack
          sx={{
            width: '360px',
            height: '319px',
            padding: '24px 8px 32px 8px',
            borderRadius: '8px',
            border: '1px',
            backgroundColor: theme.palette.purple.lightPurple,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '16px',
          }}
        >
          <img src={TurnOn} style={{ padding: '5px 0px', width: '204px', height: '157px' }} />
          <Typography
            sx={{
              fontSize: '22px',
              fontWeight: '800',
              lineHeight: '28px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: theme.palette.text.secondary,
            }}
          >
            <Trans>{t('INTEGRATION.TEAMS.REVOKE_TITLE')}</Trans>
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '18px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: theme.palette.text.secondary,
            }}
          >
            <Trans>{t('INTEGRATION.TEAMS.REVOKE_MESSAGE')}</Trans>
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  ) : (
    <Stack
      sx={{
        width: '100vw',
        height: '319px',
        padding: '24px 8px 32px 8px',
        borderRadius: '8px',
        border: '1px',
        backgroundColor: theme.palette.purple.lightPurple,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
      }}
    >
      <img src={TurnOn} style={{ padding: '5px 0px', width: '204px', height: '157px' }} />
      <Typography
        sx={{
          fontSize: '22px',
          fontWeight: '800',
          lineHeight: '28px',
          letterSpacing: '0em',
          textAlign: 'center',
          color: theme.palette.text.secondary,
        }}
      >
        <Trans>{t('INTEGRATION.TEAMS.REVOKE_TITLE')}</Trans>
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '18px',
          letterSpacing: '0em',
          textAlign: 'center',
          color: theme.palette.text.secondary,
        }}
      >
        <Trans>{t('INTEGRATION.TEAMS.REVOKE_MESSAGE')}</Trans>
      </Typography>
    </Stack>
  );
};

export default ErrorRevoked;
