import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const CustomButtonBack = styled(Button)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
  color: 'linear-gradient(90deg, #4E00D2 0%, #681FFC 35%, #8B51FF 67%, #A979FF 100%)',
  background: theme.palette.background.paper,
  boxShadow: '0px 3px 3px -2px rgba(58, 7, 189, 0.15), 0px 3px 4px rgba(58, 7, 189, 0.14)',
  borderRadius: '12px',
  '&:hover': {
    background:
      ' linear-gradient(12.27deg, rgba(58, 7, 189, 0.15) 9.12%, rgba(83, 43, 227, 0.15) 35.12%, rgba(120, 88, 237, 0.15) 59.18%, rgba(154, 126, 254, 0.15) 83.62%)',
    boxShadow: '0px 3px 3px -2px rgba(58, 7, 189, 0.15), 0px 3px 4px rgba(58, 7, 189, 0.14)',
    borderRadius: '12px',
  },
}));

export const CustomButtonNext = styled(Button)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',

  background:
    'linear-gradient(0.28deg, #3A07BD 9.12%, #532BE3 35.12%, #7858ED 59.18%, #9A7EFE 83.62%)',
  boxShadow: ' 0px 3px 3px -2px rgba(58, 7, 189, 0.15), 0px 3px 4px rgba(58, 7, 189, 0.14)',
  borderRadius: '12px',
  '&:hover': {
    background:
      ' linear-gradient(0.28deg, rgba(58, 7, 189, 0.75) 9.12%, rgba(83, 43, 227, 0.75) 35.12%, rgba(120, 88, 237, 0.75) 59.18%, rgba(154, 126, 254, 0.75) 83.62%)',
    boxShadow: ' 0px 3px 3px -2px rgba(58, 7, 189, 0.15), 0px 3px 4px rgba(58, 7, 189, 0.14)',
    borderRadius: '12px',
  },
}));
