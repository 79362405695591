import { IInitiativeParticipationBet, IInitiativeReward } from '../core/interfaces/initiative';
import { TypeInitiativeEnum } from '../core/enums/initiativeEnum';
import axiosInstance from '../core/interceptors/authInterceptor';
import { IPaginatedListParams, IQueryParamsOptions } from '../core/interfaces/serviceApi';
import { queryBuilder } from '../core/helpers/apiHelpers';

const createInitiative = async (typology: TypeInitiativeEnum) => {
  const response = await axiosInstance.post('/initiative', { type: typology });
  return response;
};

const estimateInitiative = async (payload: IInitiativeReward[], initiativeId: number) => {
  const response = await axiosInstance.post(`/initiative/estimate/${initiativeId}`, {
    rewards: payload,
  });
  return response.data;
};

const fetchInitiatives = async (options: IQueryParamsOptions) => {
  const queryOptions: IPaginatedListParams = {
    page: options.page,
    sort: options.sort,
    sortValue: options.sortValue,
    limit: options.limit,
  };

  const queryParams = queryBuilder(queryOptions);
  return await axiosInstance.post(`/initiatives${queryParams}`, {
    query: options.query,
    ...(options.startDate && { startDate: options.startDate }),
    ...(options.endDate && { endDate: options.endDate }),
    ...(options.companyId && { companyId: options.companyId }),
    ...(options.allSenderIds && { allParticipants: options.allSenderIds }),
    ...(options.participations && { participations: options.participations }),
    ...(options.statuses && { statuses: options.statuses }),
  });
};

const fetchSenders = async (id: number, queryOptions: IPaginatedListParams) => {
  const queryParams = queryBuilder(queryOptions);

  return await axiosInstance.get(`/initiative/${id}/actor/senders${queryParams}`);
};

const participateToInitiative = async (data: IInitiativeParticipationBet, initiativeId: number) => {
  return await axiosInstance.post(`/initiative/participate/${initiativeId}`, data);
};

const fetchInitiativeParticipationDetails = async (initiativeId: number) => {
  return await axiosInstance.get(`/initiative/participation/${initiativeId}`);
};

const fetchInitiativeResult = async (id: number) => {
  const response = await axiosInstance.get(`/initiative/winner/${id}`);
  return response;
};
const fetchReceivers = async (id: number, queryOptions: IPaginatedListParams) => {
  const queryParams = queryBuilder(queryOptions);

  return await axiosInstance.get(`/initiative/${id}/actor/receivers${queryParams}`);
};

const postRaffle = async (raffle: {
  prizes: string[];
  tokenId: string;
  title: string;
  slackChannel: string;
  multipleGain: boolean;
}) => {
  const response = await axiosInstance.post(`/raffle`, raffle);
  return response;
};

export {
  createInitiative,
  fetchInitiatives,
  fetchSenders,
  participateToInitiative,
  fetchInitiativeParticipationDetails,
  estimateInitiative,
  fetchInitiativeResult,
  fetchReceivers,
  postRaffle,
};
