import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertColor, IconButton, Snackbar, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { theme } from '../core/theme';
import { selectSnackbar, setSnackBar } from '../state/snackbarSlice';
import { useAppDispatch } from '../state/store';

function getStyledAlert(severity: AlertColor | undefined) {
  if (severity === 'error') {
    return {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.primary.dark,
      '& .MuiAlert-icon': {
        color: theme.palette.primary.dark,
      },
    };
  }
  if (severity === 'success') {
    return {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.primary.dark,
      '& .MuiAlert-icon': {
        color: theme.palette.primary.dark,
      },
    };
  }
}

export const CustomAlert = styled(Alert)(({ severity }) => ({
  ...getStyledAlert(severity),
}));

const SnackBar = () => {
  const dispatch = useAppDispatch();

  const { message, duration, type, open } = useSelector(selectSnackbar);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setSnackBar({ open: false, type, message, duration }));
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      sx={{
        top: 40,
        borderRadius: '20px',
        '& .MuiPaper-root': {
          borderRadius: '20px',
        },
        zIndex: 2500,
      }}
    >
      <CustomAlert
        elevation={3}
        severity={type}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
        action={action}
      >
        {message}
      </CustomAlert>
    </Snackbar>
  );
};

export default SnackBar;
