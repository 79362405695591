import { lazy } from 'react';

export const Screens = {
  Animations: {
    path: '/animations',
    Component: lazy(() => import('pages/v3/Animations')),
  },
  Unity: {
    path: '/unity',
    Component: lazy(() => import('pages/Unity')),
  },
  Home: {
    path: '/',
    Component: lazy(() => import('pages/v3/Home')),
  },
  MyCompany: {
    path: '/mycompany',
    Component: lazy(() => import('pages/v3/MyCompany')),
  },
  MyCompanyHappening: {
    path: 'happening',
    Component: lazy(() => import('pages/v3/MyCompany/Happening')),
  },
  MyCompanyScoreBoard: {
    path: 'scoreboard',
    Component: lazy(() => import('pages/v3/MyCompany/ScoreBoard')),
  },
  PostIdDetails: {
    path: '/post/:id/details',
    Component: lazy(() => import('features/PostCardList/PostDetails')),
  },
  PostIdDetailsStandAlone: {
    path: '/post/:id/details-standalone',
    Component: lazy(() => import('features/PostCardList/PostDetailsStandAlone')),
  },
  SpendingTokens: {
    path: '/spending-tokens',
    Component: lazy(() => import('pages/v3/SpendToken')),
  },
  MyCollection: {
    path: 'my-collection',
    Component: lazy(() => import('pages/v3/SpendToken/myCollection')),
  },
  MyCollectionTokenId: {
    path: 'token/:id',
    Component: lazy(() => import('pages/v3/SpendToken/myCollection/PopUpTokenCollection')),
  },
  Marketplace: {
    path: 'marketplace',
    Component: lazy(() => import('pages/v3/SpendToken/marketPlace')),
  },
  Happening: {
    path: 'happening',
    Component: lazy(() => import('pages/v3/SpendToken/happening')),
  },
  HappeningInProgress: {
    path: '/happening-in-progress',
    Component: lazy(
      () => import('pages/v3/SpendToken/happening/HappeningInprogress/HappeningInprogress')
    ),
  },
  HappeningClosed: {
    path: '/happening-closed',
    Component: lazy(() => import('pages/v3/SpendToken/happening/HappeningClosed/HappeningClosed')),
  },
  Profile: {
    path: 'profile',
    Component: lazy(() => import('pages/profile/Profile')),
  },
  ProfileEdit: {
    path: '/profile/edit',
    Component: lazy(() => import('pages/profile/editProfile/EditProfile')),
  },
  ProfileNotifications: {
    path: '/profile/notifications',
    Component: lazy(() => import('pages/profile/manageNotifications/ManageNotifications')),
  },
  ProfilePassword: {
    path: '/profile/password',
    Component: lazy(() => import('pages/profile/editPassword/EditPassword')),
  },
  ProfileLanguage: {
    path: '/profile/language',
    Component: lazy(() => import('pages/profile/editLanguage/EditLanguage')),
  },
  HelpImprovement: {
    path: '/help-improvement',
    Component: lazy(() => import('pages/impactCommunity/helpImprovement/HelpImprovement')),
  },
  Suggestions: {
    path: '/suggestions',
    Component: lazy(() => import('pages/impactCommunity/helpImprovement/Suggestions')),
  },
  PeerPecognition: {
    path: '/peer-recognition',
    Component: lazy(() => import('pages/v3/PeerRecognition')),
  },
  MarketplaceBrandsId: {
    path: '/marketplace/brands/:id',
    Component: lazy(() => import('pages/marketplace/brands/productDetails/ProductDetails')),
  },
  MarketplaceBrands: {
    path: '/marketplace/brands',
    Component: lazy(() => import('pages/marketplace/brands/productList/ProductList')),
  },
  MarketplacePurchaseApproveId: {
    path: '/marketplace/purchase/approve/:productOrderCompanyId/:managerAccountId',
  },
  UserUaffleId: {
    path: '/user-raffle/:id',
    Component: lazy(() => import('features/raffle/UserRaffleResult')),
  },
  Initiatives: {
    path: '/initiatives',
    Component: lazy(() => import('pages/impactCommunity/vote/list/InitiativesUser')),
  },
  InitiativeParticipateChallengeCandidateId: {
    path: '/initiative/participate/challenge_candidate/:id',
    Component: lazy(
      () =>
        import(
          'pages/impactCommunity/vote/participate/participateInitiativeCandidate/ParticipateInitiativeCandidate'
        )
    ),
  },
  InitiativeParticipateChallengeProjectId: {
    path: '/initiative/participate/challenge_project/:id',
    Component: lazy(
      () =>
        import(
          'pages/impactCommunity/vote/participate/participateInitiativeProject/ParticipateInitiativeProject'
        )
    ),
  },
  InitiativeResultId: {
    path: '/initiative/result/:id',
    Component: lazy(
      () =>
        import(
          'pages/impactCommunity/vote/result/resultInitiativeCandidate/ResultInitiativeCandidate'
        )
    ),
  },
  InitiativeResultProjectId: {
    path: '/initiative/result/project/:id',
    Component: lazy(
      () =>
        import('pages/impactCommunity/vote/result/resultInitiativeProject/ResultInitiativeProject')
    ),
  },
  Charities: {
    path: '/charities',
    Component: lazy(() => import('pages/charities/Charities')),
  },
  CompanyAdvantages: {
    path: '/company-advantages',
    Component: lazy(() => import('pages/prosumer/companyAdvantages/CompanyAdvantages')),
  },
  CompanyAdvantagesId: {
    path: '/company-advantages/:id',
    Component: lazy(() => import('pages/prosumer/productProsumerDetails/ProductProsumerDetails')),
  },
  NotFound: {
    path: '/notFound',
    Component: lazy(() => import('pages/errorPages/NotFound')),
  },
  Denied: {
    path: '/denied',
    Component: lazy(() => import('pages/errorPages/ErrorDeniedPage')),
  },
  Revoked: {
    path: '/revoked',
    Component: lazy(() => import('pages/errorPages/ErrorRevoked')),
  },
  ErrorCompanyNotKnown: {
    path: '/error-company-not-known',
    Component: lazy(() => import('pages/errorPages/ErrorCompanyNotKnown')),
  },
  CompanyIdLotteriesLaunchId: {
    path: ':companyId/lotteries/launch/:id',
    Component: lazy(() => import('features/raffle/raffleLaunch/RaffleLaunch')),
  },
};

export const ScreensTeams = {
  TeamsWall: {
    path: '/teams-wall',
    Component: lazy(() => import('pages/v3/Home')),
  },
  TeamsSpending: {
    path: '/teams-spending',
    Component: lazy(() => import('pages/v3/SpendToken')),
  },
  Unity: {
    path: '/unity',
    Component: lazy(() => import('pages/Unity')),
  },
  MyCollection: {
    path: 'my-collection',
    Component: lazy(() => import('pages/v3/SpendToken/myCollection')),
  },
  MyCollectionTokenId: {
    path: 'token/:id',
    Component: lazy(() => import('pages/v3/SpendToken/myCollection/PopUpTokenCollection')),
  },
  Marketplace: {
    path: 'marketplace',
    Component: lazy(() => import('pages/v3/SpendToken/marketPlace')),
  },
  Happening: {
    path: 'happening',
    Component: lazy(() => import('pages/v3/SpendToken/happening')),
  },
  HappeningInProgress: {
    path: 'happening-in-progress',
    Component: lazy(
      () => import('pages/v3/SpendToken/happening/HappeningInprogress/HappeningInprogress')
    ),
  },
  HappeningClosed: {
    path: 'happening-closed',
    Component: lazy(() => import('pages/v3/SpendToken/happening/HappeningClosed/HappeningClosed')),
  },
  PeerPecognition: {
    path: '/peer-recognition',
    Component: lazy(() => import('pages/v3/PeerRecognition')),
  },
  TeamsRecognition: {
    path: '/teams-recognition',
    Component: lazy(() => import('pages/v3/PeerRecognition')),
  },
  Charities: {
    path: '/charities',
    Component: lazy(() => import('pages/charities/Charities')),
  },
  CompanyAdvantages: {
    path: '/company-advantages',
    Component: lazy(() => import('pages/prosumer/companyAdvantages/CompanyAdvantages')),
  },
  CompanyAdvantagesId: {
    path: '/company-advantages/:id',
    Component: lazy(() => import('pages/prosumer/productProsumerDetails/ProductProsumerDetails')),
  },
  InitiativeParticipateChallengeCandidateId: {
    path: '/initiative/participate/challenge_candidate/:id',
    Component: lazy(
      () =>
        import(
          'pages/impactCommunity/vote/participate/participateInitiativeCandidate/ParticipateInitiativeCandidate'
        )
    ),
  },
  InitiativeParticipateChallengeProjectId: {
    path: '/initiative/participate/challenge_project/:id',
    Component: lazy(
      () =>
        import(
          'pages/impactCommunity/vote/participate/participateInitiativeProject/ParticipateInitiativeProject'
        )
    ),
  },
  InitiativeResultId: {
    path: '/initiative/result/:id',
    Component: lazy(
      () =>
        import(
          'pages/impactCommunity/vote/result/resultInitiativeCandidate/ResultInitiativeCandidate'
        )
    ),
  },
  InitiativeResultProjectId: {
    path: '/initiative/result/project/:id',
    Component: lazy(
      () =>
        import('pages/impactCommunity/vote/result/resultInitiativeProject/ResultInitiativeProject')
    ),
  },
  UserUaffleId: {
    path: '/user-raffle/:id',
    Component: lazy(() => import('features/raffle/UserRaffleResult')),
  },
  MarketplaceBrands: {
    path: '/marketplace/brands',
    Component: lazy(() => import('pages/marketplace/brands/productList/ProductList')),
  },
  MarketplaceBrandsId: {
    path: '/marketplace/brands/:id',
    Component: lazy(() => import('pages/marketplace/brands/productDetails/ProductDetails')),
  },
  PostIdDetails: {
    path: '/post/:id/details',
    Component: lazy(() => import('features/PostCardList/PostDetails')),
  },
  PostIdDetailsStandAlone: {
    path: '/post/:id/details-standalone',
    Component: lazy(() => import('features/PostCardList/PostDetailsStandAlone')),
  },
};
