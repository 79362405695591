import axiosInstance from '../core/interceptors/authInterceptor';
import { IInitiativeParticipationBet } from '../core/interfaces/initiative';
import {
  IInitiativeProject,
  IInitiativeProjectPayload,
} from '../core/interfaces/initiativeProject';

const fetchInitiativeProject = async (id: number) => {
  return await axiosInstance.get(`/initiative/detail/${id}`);
};

const patchInitiativeProject = async (payload: IInitiativeProjectPayload) => {
  const response = await axiosInstance.patch(`/initiative/${payload.initiativeId}`, {
    initiative: payload.initiative,
    step: payload.step,
  });
  return response;
};

const validateInitiativeProject = async (payload: {
  initiativeId: number;
  initiative: IInitiativeProject;
  step: number;
}) => {
  const response = await axiosInstance.patch(`/initiative/${payload.initiativeId}`, {
    initiative: payload.initiative,
    step: payload.step,
  });
  return response;
};

const participateToInitiativeProject = async (
  data: IInitiativeParticipationBet,
  initiativeId: number
) => {
  return await axiosInstance.post(`/initiative/${initiativeId}/participate`, data);
};

export {
  fetchInitiativeProject,
  patchInitiativeProject,
  validateInitiativeProject,
  participateToInitiativeProject,
};
