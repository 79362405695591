import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PermissionEnum } from 'core/enums/accountEnum';
import PermissionContext from './PermissionContext';

interface RestrictedProps {
  to: PermissionEnum[];
  children: React.ReactNode;
  fallback?: JSX.Element;
}

const Restricted: React.FC<RestrictedProps> = ({ to, children, fallback }) => {
  const navigate = useNavigate();
  const { isAllowedTo } = useContext(PermissionContext);
  const allowed = isAllowedTo(to);

  if (allowed) return <>{children}</>;
  if (!allowed && !fallback) navigate('/notFound');

  return <>{fallback}</>;
};

export default Restricted;
