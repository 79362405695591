export enum AccountRoleEnum {
  ISSUER = 'ISSUER',
  HOLDER = 'HOLDER',
  SUPPLIER = 'SUPPLIER',
  GROUP = 'GROUP',
  COMPANY = 'COMPANY',
  DEPARTMENT = 'DEPARTMENT',
}

export enum NotificationsTypeEnum {
  MAIL = 'MAIL',
  SLACK = 'SLACK',
  TEAMS = 'TEAMS',
  GOOGLE = 'GOOGLE',
}

export enum PermissionEnum {
  BOOST = 'BOOST',
  RECOGNITION = 'RECOGNITION',
  ACCOUNT_READ = 'ACCOUNT_READ',
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  ACCOUNT_CREATE = 'ACCOUNT_CREATE',
  ACCOUNT_DELETE = 'ACCOUNT_DELETE',
  BALANCE_READ = 'BALANCE_READ',
  TOKEN_READ = 'TOKEN_READ',
  TOKEN_CREATE = 'TOKEN_CREATE',
  TOKEN_DELETE = 'TOKEN_DELETE',
  TOKEN_MINT = 'TOKEN_MINT',
  TOKEN_TRANSFER = 'TOKEN_TRANSFER',
  TRANSACTION_READ = 'TRANSACTION_READ',
  MARKETPLACE_GIFT_CARD = 'MARKETPLACE_GIFT_CARD',
  RAFFLE = 'RAFFLE',
  PEER_TO_PEER = 'PEER_TO_PEER',
  VOTE = 'VOTE',
  HELP_AND_IMPROVEMENT = 'HELP_AND_IMPROVEMENT',
  /** @deprecated */
  ENGAGING_ACTIONS = 'ENGAGING_ACTIONS',
  MARKETPLACE_ASSOCIATION = 'MARKETPLACE_ASSOCIATION',
  MINT_PROOF_OF_BEHAVIOR = 'MINT_PROOF_OF_BEHAVIOR',
  MARKETPLACE_PROSUMER = 'MARKETPLACE_PROSUMER',
  CHALLENGE = 'CHALLENGE',
}
export enum ModuleEnum {
  'RECOGNITION_ADMIN' = 'RECOGNITION_ADMIN',
  'RECOGNITION' = 'RECOGNITION',
  'BOOST' = 'BOOST',
}

export enum LANGUAGES {
  CN = 'cn',
  EN = 'en',
  FR = 'fr',
  JP = 'jp',
  KR = 'kr',
  TW = 'tw',
}

export enum sortAccountEnum {
  DATE_ASC = 'date_asc',
  DATE_DESC = 'date_desc',
  FIRSTNAME_ASC = 'firstname_asc',
  FIRSTNAME_DESC = 'firstname_desc',
  LASTNAME_ASC = 'lastname_asc',
  LASTNAME_DESC = 'lastname_desc',
}

export enum IAccountActivatedStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum IAccountImportErrors {
  DUPLICATED = 'MISSING_FIELD',
  MISSING_FIELD = 'MISSING_FIELD',
}
