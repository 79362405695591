import React from 'react';
import NavigationContext from './NavigationContext';

interface NavigationProviderProps {
  excludedPathnames: string[];
  children: React.ReactNode;
}

const NavigationProvider: React.FC<NavigationProviderProps> = ({ excludedPathnames, children }) => {
  const menuIsShown = (currentPath: string) => {
    return !excludedPathnames.includes(currentPath);
  };

  return (
    <NavigationContext.Provider value={{ menuIsShown }}>{children}</NavigationContext.Provider>
  );
};

export default NavigationProvider;
