import React from 'react';
import { PermissionEnum } from 'core/enums/accountEnum';
import { isSubset } from 'core/helpers/arrayHelpers';
import PermissionContext from './PermissionContext';

interface PermissionProviderProps {
  accountPermissions: PermissionEnum[];
  children: React.ReactNode;
}

const PermissionProvider: React.FC<PermissionProviderProps> = ({
  accountPermissions,
  children,
}) => {
  const isAllowedTo = (permission: PermissionEnum[]) => {
    return isSubset(accountPermissions, permission);
  };

  return (
    <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>
  );
};

export default PermissionProvider;
