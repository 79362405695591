import { queryBuilder } from 'core/helpers/apiHelpers';
import axiosInstance from 'core/interceptors/authInterceptor';
import { ICharitiesOptions } from 'core/interfaces/charity';
import { IPaginatedListParams } from 'core/interfaces/serviceApi';

const fetchCharities = async (options: ICharitiesOptions) => {
  const queryOptions: IPaginatedListParams = {
    page: options.page,
    limit: options.limit,
    sort: options.sort,
    sortValue: options.sortValue,
  };

  const queryParams = queryBuilder(queryOptions);

  return await axiosInstance.post(`/marketplace/products/charitips/charities/${queryParams}`, {
    ...(options.goals && { goals: options.goals }),
  });
};

const validateCharityDonation = async (charityId: string, amount: number) => {
  return await axiosInstance.post(`/marketplace/purchase/association/${charityId}`, { amount });
};

export { fetchCharities, validateCharityDonation };
