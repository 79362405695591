import React from 'react';
import { PermissionEnum } from 'core/enums/accountEnum';

interface PermissionContextProps {
  isAllowedTo: (permision: PermissionEnum[]) => boolean;
}

const deafaultPermissionBehavior: PermissionContextProps = {
  isAllowedTo: () => false,
};

const PermissionContext = React.createContext(deafaultPermissionBehavior);

export default PermissionContext;
