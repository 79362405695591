import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from 'axios';
import { RequestStatusEnum } from 'core/enums/stateEnum';
import { IMe } from 'core/interfaces/account';
import { IAccountsInventoryOptions } from 'core/interfaces/accountApi';
import { IResponseError } from 'core/interfaces/status';
import i18next from 'i18next';
import { getAccountsListDetail } from 'services/accountApi';
import { RootState } from './rootReducer';
import { setSnackBar } from './snackbarSlice';

interface IContactState {
  items: IMe[];
  currentPage: number;
  total: number;
  selectedContacts: IMe[];
  loading: RequestStatusEnum;
  error: IResponseError | null;
}

const initialState: IContactState = {
  items: [],
  currentPage: 0,
  total: 0,
  selectedContacts: [],
  loading: RequestStatusEnum.IDLE,
  error: {} as IResponseError,
};

export const getAccountsListDetailThunk = createAsyncThunk(
  'contact/getAccountsListDetailThunk',
  async (options: IAccountsInventoryOptions, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAccountsListDetail(options);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    resetSelectedContacts: (state: IContactState) => {
      state.selectedContacts = [];
    },
    addContact: (state: IContactState, { payload }: PayloadAction<IMe>) => {
      state.selectedContacts = [...state.selectedContacts, payload];
    },
    removeContact: (state: IContactState, { payload }: PayloadAction<IMe>) => {
      state.selectedContacts = state.selectedContacts.filter(
        (account) => account.accountId !== payload.accountId
      );
    },
    resetContacts: (state: IContactState) => {
      state.items = [];
    },
    setSelectedAccount: (state: IContactState, { payload }: PayloadAction<IMe>) => {
      state.selectedContacts = [payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountsListDetailThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(getAccountsListDetailThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      if (state.currentPage === 0) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }
      return state;
    });
  },
});

export const {
  resetSelectedContacts,
  addContact,
  removeContact,
  resetContacts,
  setSelectedAccount,
} = contactSlice.actions;

export const selectContact = (state: RootState) => state.contactReducer;

export default contactSlice.reducer;
