import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatusEnum } from '../core/enums/stateEnum';
import { IResponseError } from '../core/interfaces/status';
import { RootState } from './rootReducer';

import request from 'axios';
import i18next from 'i18next';
import { setSnackBar } from './snackbarSlice';
import { createInitiative } from '../services/initiativeApi';
import { TypeInitiativeEnum } from '../core/enums/initiativeEnum';
import {
  fetchInitiativeProject,
  patchInitiativeProject,
  validateInitiativeProject,
} from '../services/initiativeProjectApi';
import {
  IInitiativeProject,
  IInitiativeProjectPayload,
  IInitiativeProjectResponse,
} from '../core/interfaces/initiativeProject';
import { initiativeProjectInitialState } from '../core/constants/initiativeProject';

interface IInitiativeProjectState {
  initiative: IInitiativeProject;
  step: number;
  validBalance: boolean;
  loading: RequestStatusEnum;
  error: IResponseError;
}

const initialState: IInitiativeProjectState = {
  initiative: initiativeProjectInitialState,
  step: 0,
  validBalance: false,
  loading: RequestStatusEnum.IDLE,
  error: {} as IResponseError,
};

export const fetchInitiativeProjectThunk = createAsyncThunk(
  'initiativeProject/fetchInitiativeProjectThunk',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchInitiativeProject(id);
      return response.data as IInitiativeProjectResponse;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const createInitiativeThunk = createAsyncThunk(
  'initiativeProject/createInitiativeThunk',
  async (typology: TypeInitiativeEnum, { rejectWithValue, dispatch }) => {
    try {
      const response = await createInitiative(typology);
      return response.data as IInitiativeProjectResponse;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const patchInitiativeProjectThunk = createAsyncThunk(
  'initiativeProject/patchInitiativeProjectThunk',
  async (data: IInitiativeProjectPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await patchInitiativeProject(data);
      return response.data as IInitiativeProjectResponse;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const validateInitiativeProjectThunk = createAsyncThunk(
  'initiativeProject/validateInitiativeProjectThunk',
  async (
    data: { initiativeId: number; initiative: IInitiativeProject; step: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await validateInitiativeProject(data);
      return response.data as IInitiativeProjectResponse;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);
const initiativeProjectSlice = createSlice({
  name: 'initiativeProject',
  initialState,
  reducers: {
    setCurrentStep: (state: IInitiativeProjectState, action: { payload: number }) => {
      state.step = action.payload;
    },
    setValidProjectsBalance: (state: IInitiativeProjectState, action: { payload: boolean }) => {
      state.validBalance = action.payload;
    },
    resetInitiative: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInitiativeProjectThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(fetchInitiativeProjectThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.initiative = {
        ...action?.payload?.initiative,
      } as IInitiativeProject;
      state.step = action?.payload?.step as number;
      return state;
    });
    builder.addCase(fetchInitiativeProjectThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
    builder.addCase(createInitiativeThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(createInitiativeThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.initiative = {
        ...action?.payload?.initiative,
      } as IInitiativeProject;
      return state;
    });
    builder.addCase(createInitiativeThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
    builder.addCase(patchInitiativeProjectThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(patchInitiativeProjectThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.initiative = {
        ...action?.payload?.initiative,
      } as IInitiativeProject;
      return state;
    });
    builder.addCase(patchInitiativeProjectThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
    builder.addCase(validateInitiativeProjectThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(validateInitiativeProjectThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.initiative = {
        ...action?.payload?.initiative,
      } as IInitiativeProject;
      return state;
    });
    builder.addCase(validateInitiativeProjectThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
  },
});

export const { setCurrentStep, resetInitiative, setValidProjectsBalance } =
  initiativeProjectSlice.actions;

export const selectInitiativeProject = (state: RootState) => state.initiativeProjectReducer;

export default initiativeProjectSlice.reducer;
