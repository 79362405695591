import axiosInstance from '../core/interceptors/authInterceptor';

interface ISubmitErrorMessagePayload {
  firstName: string | undefined;
  lastName: string | undefined;
  company?: string;
  reason?: string;
  email?: string;
  managerEmail?: string;
  message: string;
}

const submitErrorMessage = async (payload: ISubmitErrorMessagePayload) => {
  return await axiosInstance.post(`/help/teams`, {
    lastName: payload.lastName,
    firstName: payload.firstName,
    managerEmail: payload.managerEmail,
    mail: payload.email,
    message: payload.message,
    company: payload.company,
    reason: payload.reason,
  });
};

export { submitErrorMessage };
