export enum queryParamsEnum {
  ASC = 'asc',
  DESC = 'desc',
  RECEIVED_AT = 'receivedAt',
  CREATED_AT = 'createdAt',
  QUANTITY = 'quantity',
  MINTED = 'minted',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  NAME = 'name',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  CATEGORY = 'category',
  ACTION_FR = 'actionFr',
  ACTION_EN = 'actionEn',
  TITLE = 'title',
}
