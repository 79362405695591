import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setSnackBar } from './snackbarSlice';
import i18next from 'i18next';
import { IResponseError } from 'core/interfaces/status';
import { postRevealWinner, postStartRaffle } from 'services/raffleApi';
import { RequestStatusEnum } from 'core/enums/stateEnum';
import request from 'axios';
import { RootState } from './rootReducer';
import { IRaffle } from 'core/interfaces/raffle';

interface IRaffleState {
  items: IRaffle[];
  total: number;
  currentPage: number;
  error: IResponseError;
  loading: RequestStatusEnum;
}

const initialState: IRaffleState = {
  items: [],
  total: 0,
  currentPage: 0,
  error: {} as IResponseError,
  loading: RequestStatusEnum.IDLE,
};

export const postStartRaffleThunk = createAsyncThunk(
  'raffle/postStartRaffleThunk',
  async (raffleId: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await postStartRaffle(raffleId);

      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const postRevealWinnerRaffleThunk = createAsyncThunk(
  'raffle/postRevealWinnerRaffleThunk',
  async (raffleWinnerId: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await postRevealWinner(raffleWinnerId);

      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const raffleSlice = createSlice({
  name: 'raffle',
  initialState,
  reducers: {
    resetRaffles: (state: IRaffleState) => {
      state.items = [];
      state.total = 0;
      state.currentPage = 0;
      state.loading = RequestStatusEnum.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postStartRaffleThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(postStartRaffleThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      state.items = action.payload.items;
      return state;
    });
    builder.addCase(postStartRaffleThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });

    builder.addCase(postRevealWinnerRaffleThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(postRevealWinnerRaffleThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      state.items = action.payload.items;
      return state;
    });
    builder.addCase(postRevealWinnerRaffleThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
  },
});

export const { resetRaffles } = raffleSlice.actions;

export const selectRaffle = (state: RootState) => state.raffleReducer;

export default raffleSlice.reducer;
