import { queryBuilder } from 'core/helpers/apiHelpers';
import axiosInstance from 'core/interceptors/authInterceptor';
import { ICompanyRafflePayload } from 'core/interfaces/company';
import { IPaginatedListParams } from 'core/interfaces/serviceApi';

const createRaffle = async (lotteryPayload: ICompanyRafflePayload) => {
  return await axiosInstance.post('/raffle', lotteryPayload);
};

// Add correct queryOptions
const fetchRaffle = async (companyId: number) => {
  const queryOptions: IPaginatedListParams = {
    page: 0,
    limit: 10,
  };
  const queryParams = queryBuilder(queryOptions);

  return await axiosInstance.post(`/raffle/list/${queryParams}`, {
    companyId,
  });
};

const fetchRaffleById = async (raffleId: number) => {
  return await axiosInstance.get(`/raffle/${raffleId}`);
};

const fetchChanceRaffle = async (raffleId: number) => {
  return await axiosInstance.get(`/raffle/chance/${raffleId}`);
};

const postStartRaffle = async (raffleId: number) => {
  return await axiosInstance.post(`/raffle/draw/${raffleId}`);
};

const fetchResultsRaffle = async (raffleId: number) => {
  return await axiosInstance.get(`/raffle/winners/${raffleId}`);
};

const postRevealWinner = async (raffleWinnerId: number) => {
  return await axiosInstance.post(`/raffle/reveal/${raffleWinnerId}`);
};

export {
  createRaffle,
  fetchRaffle,
  fetchRaffleById,
  fetchChanceRaffle,
  postStartRaffle,
  fetchResultsRaffle,
  postRevealWinner,
};
