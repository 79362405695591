import { Backdrop, CircularProgress, Stack } from '@mui/material';
import { FC } from 'react';
import { theme } from '../core/theme';
interface ISpinnerProps {
  hasBackdrop?: boolean;
}
const Spinner: FC<ISpinnerProps> = ({ hasBackdrop = false }) => {
  const renderSvg = () => {
    return (
      <>
        <svg width="1" height="1">
          <radialGradient
            id="linearColors"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(52 49) rotate(165.964) scale(53.6004 51.9333)"
          >
            <stop offset="0.278548" stopColor="#BA1765" />
            <stop offset="0.526347" stopColor="#FF3E5B" />
            <stop offset="0.88404" stopColor="#F43173" />
          </radialGradient>
        </svg>
        <CircularProgress
          thickness={4}
          sx={{
            '&	.MuiCircularProgress-circle': {
              stroke: 'url(#linearColors)',
            },
          }}
        />
      </>
    );
  };
  return hasBackdrop ? (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: theme.palette.primary.light,
        backdropFilter: 'blur(4px)',
      }}
      open={true}
    >
      {renderSvg()}
    </Backdrop>
  ) : (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', height: '100px', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      {renderSvg()}
    </Stack>
  );
};

export default Spinner;
