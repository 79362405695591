import { create } from 'zustand';
import { app } from '@microsoft/teams-js';

export interface IDefaultLayoutSetings {
  title: string;
  RightSideBarContent: () => JSX.Element | null;
  hasRightSideBarContent: boolean;
  hasSplitedRightSideBarContent?: boolean;
}

interface IDefaultLayoutStore {
  thirdPartyContext: null | app.Context;
  settings: IDefaultLayoutSetings;
  setSettings: (settings: IDefaultLayoutSetings) => void;
  setTitle: (updateTitle: (currentTitle: string) => string) => void;
  setThirdPartyContext: (thirdPartyContext: app.Context) => void;
  reset: () => void;
}

const defaultLayoutStoreValues = {
  thirdPartyContext: null,
  settings: {
    title: '',
    RightSideBarContent: () => null,
    hasRightSideBarContent: false,
    hasSplitedRightSideBarContent: false,
  },
};

class DefaultLayoutStore {
  useStore = create<IDefaultLayoutStore>()((set) => ({
    ...defaultLayoutStoreValues,
    setSettings: (settings: IDefaultLayoutSetings) => {
      set({ settings });
    },
    setTitle: (updateTitle: (currentTitle: string) => string) => {
      set((state) => ({
        settings: { ...state.settings, title: updateTitle(state.settings.title) },
      }));
    },
    setThirdPartyContext: (thirdPartyContext) =>
      set(() => ({
        thirdPartyContext,
      })),
    reset: () => {
      set(() => ({
        settings: { ...defaultLayoutStoreValues.settings },
      }));
    },
  }));
}

export default new DefaultLayoutStore();
