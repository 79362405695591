import { app, authentication } from '@microsoft/teams-js';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import axios, { AxiosRequestConfig } from 'axios';
import { addResourceBundle } from 'i18nConfig';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import './i18nConfig';
import { useNavigate } from 'react-router-dom';
import Spinner from './components/Spinner';
import { IMe } from './core/interfaces/account';
import { TeamsRoutes } from './core/routes/TeamsRoutes';
import { theme } from './core/theme';
import { updateAccount } from './state/accountSlice';
import { useAppDispatch } from './state/store';
import * as Sentry from '@sentry/react';
import Store from './layouts/DefaultLayout.store';

const fetchAccountWithBearerConfig = (bearer: string): AxiosRequestConfig<IMe> => ({
  method: 'GET',
  url: `${process.env.REACT_APP_API_URL}/me`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${bearer}`,
  },
});

const Teams = () => {
  const { setThirdPartyContext } = Store.useStore((state) => state);

  app.initialize();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [displaySpinner, setDisplaySpinner] = useState<boolean>(true);
  //const [tabId, setTabId] = useState<string | null>(null);
  useEffect(() => {
    authentication.getAuthToken().then(ssoLoginSuccess).catch(ssoLoginFailure);
    app
      .getContext()
      .then((context) => {
        setThirdPartyContext(context);
        setTabIdFromContext(context);
      })
      .catch(console.error);
    // eslint-disable-next-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*tabId*/]);

  function setTabIdFromContext(context: app.Context) {
    const subPageId = context?.page?.subPageId;
    const tabIdFromContext = context.page?.id;
    /**
     * Is the current tab allowed to navigate to the context page
     */
    const isAllowedTab = [
      process.env.REACT_APP_MICROSOFT_STATIC_TAB_WALL_ID,
      process.env.REACT_APP_MICROSOFT_STATIC_TAB_SPENDING_ID,
    ].includes(tabIdFromContext);
    /**
     * Get the tab context from Teams and set it in the state
     */
    //setTabId(tabIdFromContext);
    /**
     * subPageId contains /post/:postId/details (sent from backend deeplink)
     * open the post details page only if the current page is the wall
     */
    if (subPageId && isAllowedTab) {
      navigate(subPageId);
    }
  }

  function ssoLoginFailure(error: string) {
    setDisplaySpinner(false);
    console.error('SSO failed: ', error);
  }

  async function ssoLoginSuccess(token: string) {
    localStorage.setItem('idtoken', token);
    const { data: account } = await axios(fetchAccountWithBearerConfig(token));
    const language = account.language;
    if (typeof language !== 'undefined') addResourceBundle(language);
    const updatedAccount: IMe = { ...account, isFromTeams: true };
    dispatch(updateAccount({ ...updatedAccount }));
    setDisplaySpinner(false);
    Sentry.setUser({ ...updatedAccount });
    Sentry.setTag('is_from_teams', true);
    Sentry.setTag('email', updatedAccount.email);
    Sentry.setTag('company_id', updatedAccount?.company?.companyId);
    Sentry.setTag('role', updatedAccount?.role);
    await i18next.changeLanguage(account.language);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box height="100%">
        <CssBaseline />
        {!displaySpinner ? <TeamsRoutes /> : <Spinner />}
      </Box>
    </ThemeProvider>
  );
};

export default Teams;
