import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatusEnum } from '../core/enums/stateEnum';
import { IResponseError } from '../core/interfaces/status';
import { RootState } from './rootReducer';
import { IInitiativeBasicDetails, IInitiativeOptions } from '../core/interfaces/initiative';
import { fetchInitiatives } from '../services/initiativeApi';
import request from 'axios';
import { setSnackBar } from './snackbarSlice';
import i18next from 'i18next';

interface IInitiativesState {
  items: IInitiativeBasicDetails[];
  total: number;
  loading: RequestStatusEnum;
  error: IResponseError;
  currentPage: number;
}

const initialState: IInitiativesState = {
  items: [],
  total: 0,
  loading: RequestStatusEnum.IDLE,
  error: {} as IResponseError,
  currentPage: 0,
};

export const fetchInitiativesThunk = createAsyncThunk(
  'initiative/fetchInitiativesThunk',
  async (options: IInitiativeOptions, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchInitiatives(options);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);
const sortInitiativeByStatus = (items: IInitiativeBasicDetails[]) => {
  return items.sort((a: IInitiativeBasicDetails, b: IInitiativeBasicDetails) => {
    if (a.status === 'IN_PROGRESS') return -1;
    if (b.status === 'IN_PROGRESS') return 1;
    if (a.status === 'VALIDATED') return -1;
    if (b.status === 'VALIDATED') return 1;
    if (a.status === 'CLOSED') return -1;
    if (b.status === 'CLOSED') return 1;
    return 0;
  });
};

const initiativesSlice = createSlice({
  name: 'initiative',
  initialState,
  reducers: {
    resetInitiatives: (state: IInitiativesState) => {
      state.items = [];
      state.total = 0;
      state.currentPage = 0;
      state.loading = RequestStatusEnum.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInitiativesThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(fetchInitiativesThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      if (state.currentPage === 0) {
        state.items = sortInitiativeByStatus(action.payload.items);
      } else {
        state.items = [...state.items, ...sortInitiativeByStatus(action.payload.items)];
      }
      return state;
    });
    builder.addCase(fetchInitiativesThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
  },
});

export const { resetInitiatives } = initiativesSlice.actions;

export const selectInitiatives = (state: RootState) => state.initiativesReducer;

export default initiativesSlice.reducer;
