import {
  RewardsReasonEnum,
  StatusInitiativeEnum,
  TokenOriginEnum,
  TypeInitiativeEnum,
} from '../enums/initiativeEnum';
import { IActor } from '../interfaces/initiative';
import { IChallengeProject, IInitiativeProject } from '../interfaces/initiativeProject';

export const initiativeProjectInitialState = {
  initiativeId: null,
  typology: TypeInitiativeEnum.CHALLENGE_PROJECT,
  company: null,
  category: null,
  name: '',
  startDate: undefined,
  endDate: undefined,
  description: '',
  status: StatusInitiativeEnum.DRAFT,
  spendingRule: {
    token: null,
    origin: TokenOriginEnum.EMPLOYEE_PORTFOLIO,
    quantity: 1,
    min: null,
    max: null,
  },
  levelRule: {
    token: {
      tokenId: null,
      name: null,
      picture: null,
      stepLevels: [],
    },
    levels: [
      {
        stepLevel: null,
        multiplier: 1,
      },
      {
        stepLevel: null,
        multiplier: 1,
      },
      {
        stepLevel: null,
        multiplier: 1,
      },
      {
        stepLevel: null,
        multiplier: 1,
      },
    ],
  },
  rewards: [
    {
      gain: {
        token: null,
        quantity: null,
      },
      speedStartDate: null,
      speedEndDate: null,
      type: RewardsReasonEnum.RANKING,
      quantityRequired: 1,
    },
    {
      gain: {
        token: {
          tokenId: null,
          name: null,
          category: null,
          stepLevels: [],
        },
        quantity: 1,
      },
      speedStartDate: null,
      speedEndDate: null,
      type: RewardsReasonEnum.PARTICIPATION,
      quantityRequired: null,
    },
  ],
  participants: [] as IActor[], // Participants
  actorsInformation: {
    allParticipants: false,
    nbParticipants: 0,
  },
  projects: [
    { id: null, description: null, picture: null, name: null },
    { id: null, description: null, picture: null, name: null },
    { id: null, description: null, picture: null, name: null },
    { id: null, description: null, picture: null, name: null },
    { id: null, description: null, picture: null, name: null },
  ] as IChallengeProject[],
  projectOwner: null,
} as IInitiativeProject;
