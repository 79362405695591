import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postActionValid, fetchAllActions, patchAction } from '../services/acrossCompaniesApi';
import { setSnackBar } from './snackbarSlice';
import request from 'axios';
import i18next from 'i18next';
import { IResponseError } from '../core/interfaces/status';
import {
  IEngagedActionsOptions,
  IEngagedActionForm,
  IEngagedAction,
  IEngagedActionPayload,
} from '../core/interfaces/acrossCompanies';
import { RequestStatusEnum } from '../core/enums/stateEnum';
import { RootState } from './rootReducer';

interface IAcrossCompaniesState {
  items: IEngagedAction[];
  loading: RequestStatusEnum;
  error: IResponseError;
  currentPage: number;
  total: number;
}

const initialState: IAcrossCompaniesState = {
  items: [],
  loading: RequestStatusEnum.IDLE,
  error: {} as IResponseError,
  currentPage: 0,
  total: 0,
};

export const createActionValidThunk = createAsyncThunk(
  'acrossCompanies/createActionValidThunk',
  async (data: IEngagedActionForm, { rejectWithValue, dispatch }) => {
    try {
      const response = await postActionValid(data);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const fetchAllActionValidThunk = createAsyncThunk(
  'initiative/fetchAllActionValidThunk',
  async (options: IEngagedActionsOptions, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAllActions(options);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const patchActionThunk = createAsyncThunk(
  'initiative/patchActionThunk',
  async (action: IEngagedActionPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await patchAction(action);
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const acrossCompaniesSlice = createSlice({
  name: 'acrossCompanies',
  initialState,
  reducers: {
    resetAcrossCompanies: (state: IAcrossCompaniesState) => {
      state.items = [];
      state.currentPage = 0;
      state.loading = RequestStatusEnum.IDLE;
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createActionValidThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(createActionValidThunk.fulfilled, (state) => {
      state.loading = RequestStatusEnum.FULFILLED;
      return state;
    });
    builder.addCase(createActionValidThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });

    builder.addCase(patchActionThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });

    builder.addCase(fetchAllActionValidThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(fetchAllActionValidThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      if (state.currentPage === 0) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }
      return state;
    });
    builder.addCase(fetchAllActionValidThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
  },
});

export const { resetAcrossCompanies } = acrossCompaniesSlice.actions;

export const selectActions = (state: RootState) => state.actionsReducer;

export default acrossCompaniesSlice.reducer;
