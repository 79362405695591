import { Box, Stack, Typography, styled } from '@mui/material';

export const BoxContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.commonPalette.lightBeige,
  paddingTop: 2,
  paddingX: 10,
  paddingBottom: 3,
  width: '90%',
  border: `none`,
  padding: '1rem 3rem',
  borderRadius: '12px',
}));

export const StackContainer = styled(Stack)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const TitleTypography = styled(Typography)({
  fontSize: 20,
  fontWeight: 600,
  margin: '1rem 0',
  paddingBottom: '20px',
});

export const MessageTypography = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.text.primary,
}));

export const StackButtonContainer = styled(Stack)({
  paddingTop: '50px',
  paddingBottom: '12px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

export const CustomModalBoxContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.commonPalette.lightBeige,
  paddingTop: 2,
  paddingX: 10,
  paddingBottom: 3,
  border: `none`,
  padding: '1rem 2rem',
  borderRadius: '12px',
  maxHeight: '90%',
  overflowY: 'auto',
}));
