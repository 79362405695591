import { IInitiative } from './../core/interfaces/initiative';
import { IInitiativePayload } from '../core/interfaces/initiative';
import axiosInstance from '../core/interceptors/authInterceptor';

const fetchInitiativeCandidate = async (id: number) => {
  const response = await axiosInstance.get(`/initiative/detail/${id}`);
  return response;
};

const patchInitiativeCandidate = async (payload: IInitiativePayload) => {
  const response = await axiosInstance.patch(`/initiative/${payload.initiativeId}`, {
    initiative: payload.initiative,
    step: payload.step,
  });
  return response;
};

const validateInitiativeCandidate = async (payload: {
  initiativeId: number;
  initiative: IInitiative;
  step: number;
}) => {
  const response = await axiosInstance.patch(`/initiative/${payload.initiativeId}`, {
    initiative: payload?.initiative,
    step: payload.step,
  });
  return response;
};

export { fetchInitiativeCandidate, patchInitiativeCandidate, validateInitiativeCandidate };
