const cdnHostName = process.env.REACT_APP_CDN_HOSTNAME;

export const convertFilePathToFullUrl = (filePath: string | undefined | null): string | null => {
  if (!filePath) {
    return null;
  }

  const CLOUDFRONT_PREFIX = 'cloudfront://';

  if (filePath.indexOf(CLOUDFRONT_PREFIX) > -1) {
    return filePath.replace(CLOUDFRONT_PREFIX, 'https://' + cdnHostName + '/');
  }
  return filePath;
};

export const getUrlWithCdn = (filePath: string) => {
  return `https://${cdnHostName}/${filePath}`;
};
