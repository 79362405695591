import { combineReducers } from '@reduxjs/toolkit';

import accountSlice from './accountSlice';
import tokenSlice from './tokenSlice';
import snackbarSlice from './snackbarSlice';
import contactSlice from './contactSlice';
import initiativeSlice from './initiativeSlice';
import initiativesSlice from './initiativesSlice';
import initiativeProjectSlice from './initiativeProjectSlice';
import acrossCompaniesSlice from './acrossCompaniesSlice';
import raffleSlice from './raffleSlice';
import companySlice from './companySlice';
import charitiesSlice from './charitiesSlice';
import animationSlice from './animationSlice';

const rootReducer = combineReducers({
  animationReducer: animationSlice,
  accountReducer: accountSlice,
  tokenReducer: tokenSlice,
  snackbarReducer: snackbarSlice,
  contactReducer: contactSlice,
  initiativeReducer: initiativeSlice,
  initiativeProjectReducer: initiativeProjectSlice,
  initiativesReducer: initiativesSlice,
  actionsReducer: acrossCompaniesSlice,
  raffleReducer: raffleSlice,
  companyReducer: companySlice,
  charitiesReducer: charitiesSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
