import { useState, FC, useEffect, CSSProperties } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Stack } from '@mui/material';
import { theme } from 'core/theme';
import ButtonDesktopForm from '../ButtonDesktopForm/ButtonDesktopForm';
import {
  CustomModalBoxContainer,
  MessageTypography,
  StackButtonContainer,
  StackContainer,
  TitleTypography,
} from './popUpStyle';

export interface PopUpPros {
  title?: string;
  message: string | React.ReactNode;
  submitText?: string;
  cancelText?: string;
  open: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
  styleOverrides?: CSSProperties;
}

const PopUp: FC<PopUpPros> = ({
  title,
  message,
  submitText = 'COMMON.BUTTONS.SUBMIT',
  cancelText = 'COMMON.BUTTONS.CANCEL',
  open,
  onClose,
  onSubmit,
  styleOverrides,
}) => {
  const [openPopUp, setOpenPopUp] = useState(false);
  const isBackButton = cancelText && onClose ? true : false;

  const isNextButton = submitText && onSubmit ? true : false;

  const handleClose = () => {
    onClose && onClose();
  };

  useEffect(() => {
    setOpenPopUp(open);
  }, [open]);

  return (
    <Modal
      open={openPopUp}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: theme.palette.purple.backdropPurple,
          backdropFilter: 'blur(4px)',
        },
        zIndex: 2001,
      }}
    >
      <CustomModalBoxContainer sx={{ ...styleOverrides }}>
        <Stack alignItems={'flex-end'}>
          <CloseIcon
            sx={{ color: theme.palette.text.primary, cursor: 'pointer' }}
            onClick={handleClose}
          />
        </Stack>
        <StackContainer spacing={1}>
          <TitleTypography id="modal-title" variant="subtitle1">
            {title}
          </TitleTypography>
          <MessageTypography id="modal-description" variant="subtitle1">
            {message}
          </MessageTypography>
          <StackButtonContainer>
            <ButtonDesktopForm
              styleOverrides={{
                display: 'flex',
                height: '45px',
              }}
              hasBackButton={isBackButton}
              hasNextButton={isNextButton}
              nextButtonLabel={submitText}
              backButtonLabel={cancelText}
              onClickFuncBack={onClose}
              onClickFuncNext={onSubmit}
            />
          </StackButtonContainer>
        </StackContainer>
      </CustomModalBoxContainer>
    </Modal>
  );
};

export default PopUp;
