import { app, authentication } from '@microsoft/teams-js';
import axios, { AxiosError, AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import i18next from 'i18next';
import { addResourceBundle } from '../../i18nConfig';
import { updateAccount } from '../../state/accountSlice';
import { LANGUAGES } from '../enums/accountEnum';
import { IMe } from '../interfaces/account';
import { useErrorHandler } from './authInterceptorErrorsHandler';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL, timeout: 60000 });

axiosInstance.interceptors.request.use(handleFullfilled, handleRejected);

axiosInstance.interceptors.response.use(
  (response) => response,
  (e) => {
    useErrorHandler.setState({ error: e });
    return Promise.reject(e);
  }
);

function IsTeamsTab() {
  if (!window) return false;
  if (window.name === 'embedded-page-container' || window.name === 'extension-tab-frame')
    return true;
  const regex = new RegExp('TeamsMobile-iOS|TeamsMobile-Android', 'gi');
  return regex.test(window.navigator.userAgent);
}

async function getMeWithSSOToken(token: string): Promise<IMe> {
  const headers = new AxiosHeaders()
    .setContentType('application/json')
    .setAuthorization(`Bearer ${token}`);

  const { data: account } = await axios.get(`${process.env.REACT_APP_API_URL}/me`, { headers });
  return account;
}

async function handleFullfilled(
  request: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> {
  if (IsTeamsTab()) return handleTeamsRequest(request);

  return handleNonTeamsRequest(request);
}

async function handleTeamsRequest(
  request: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> {
  await app.initialize();

  const idToken = localStorage.getItem('idtoken') as string;
  if (idToken) {
    request.headers.setAuthorization(`Bearer ${idToken}`);
    return request;
  }

  const ssoToken = await authentication.getAuthToken();

  if (!ssoToken)
    return Promise.reject({
      code: AxiosError.ERR_BAD_REQUEST,
      message: 'SSO_TOKEN_NOT_FOUND',
    });

  localStorage.setItem('idtoken', ssoToken);

  const me = await getMeWithSSOToken(ssoToken);
  updateAccount({ ...me, isFromTeams: true });

  const language = me?.language || LANGUAGES.EN;
  await addResourceBundle(language);
  await i18next.changeLanguage(language);

  request.headers.setAuthorization(`Bearer ${ssoToken}`);
  return request;
}

async function handleNonTeamsRequest(
  request: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> {
  const accessToken = localStorage.getItem('accessToken') as string;
  const accessTokenObject = JSON.parse(accessToken);

  if (!accessTokenObject || !accessTokenObject?.access_token) {
    return Promise.reject({
      code: AxiosError.ERR_BAD_REQUEST,
      message: 'ACCESS_TOKEN_NOT_FOUND',
    });
  }

  request.headers.setAuthorization(`Bearer ${accessTokenObject.access_token}`);

  return request;
}

async function handleRejected(error: AxiosError) {
  return Promise.reject(error);
}

export default axiosInstance;
