import { FC, CSSProperties, ReactNode } from 'react';
import { Box } from '@mui/material';
import { Normalize, useTranslation } from 'react-i18next';
import { CustomButtonBack, CustomButtonNext } from './buttonDesktopFormStyle';
import { theme } from '../../core/theme';

export interface IButtonDesktopForm {
  backButtonLabel?: string;
  nextButtonLabel?: string;
  styleOverrides?: CSSProperties;
  styleOverridesBtnNext?: CSSProperties;
  styleOverridesBtnBack?: CSSProperties;
  onClickFuncNext?: () => void;
  onClickFuncBack?: () => void;
  isDisabled?: boolean;
  hasBackButton?: boolean;
  hasNextButton?: boolean;
  hasIcon?: ReactNode;
}

const ButtonDesktopForm: FC<IButtonDesktopForm> = ({
  backButtonLabel,
  nextButtonLabel,
  styleOverrides,
  styleOverridesBtnNext,
  styleOverridesBtnBack,
  onClickFuncNext,
  onClickFuncBack,
  isDisabled,
  hasBackButton = true,
  hasNextButton = true,
  hasIcon,
}) => {
  const [t] = useTranslation();
  const i18nTranslation = nextButtonLabel as Normalize<object> 
  return (
    <Box sx={{ ...styleOverrides }}>
      {hasBackButton && backButtonLabel && (
        <CustomButtonBack
          sx={{
            p: '12px 27px 12px 27px',
            mr: '10px',
            ...styleOverridesBtnBack,
          }}
          variant="outlined"
          onClick={onClickFuncBack}
          startIcon={hasIcon}
        >
          {t(backButtonLabel as Normalize<object>)}
        </CustomButtonBack>
      )}
      {hasNextButton && nextButtonLabel && (
        <CustomButtonNext
          sx={{
            width: '100%',
            '&.Mui-disabled': {
              border: `1px solid ${theme.palette.secondary.dark}`,
              boxShadow:
                '0px 3px 3px -2px rgba(58, 7, 189, 0.15), 0px 3px 4px rgba(58, 7, 189, 0.14)',
              borderRadius: '12px',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
            },
            ...styleOverridesBtnNext,
          }}
          variant="contained"
          type="submit"
          onClick={onClickFuncNext}
          disabled={isDisabled || false}
          startIcon={hasIcon}
        >
          {t(i18nTranslation)}
        </CustomButtonNext>
      )}
    </Box>
  );
};

export default ButtonDesktopForm;
