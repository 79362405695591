import { IAccountsInventoryOptions } from 'core/interfaces/accountApi';

const queryParamsBuilder = (options: IAccountsInventoryOptions) => {
  const params = [];
  let index = 0;

  for (const property in options) {
    type optionsKey = keyof typeof options;

    if (
      options[property as optionsKey] !== '' &&
      options[property as optionsKey] !== null &&
      options[property as optionsKey] !== undefined
    ) {
      params.push(
        index === 0
          ? `?${property}=${options[property as optionsKey]}`
          : `&${property}=${options[property as optionsKey]}`
      );
    }
    index++;
  }

  return params.join('');
};

export { queryParamsBuilder };
