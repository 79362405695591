import { useTranslation } from 'react-i18next';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Accordion,
  Divider,
  Fab,
  Paper,
  Stack,
  Typography,
  Avatar,
} from '@mui/material';
import { TitleTypography } from './noAffiliatedStyle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeBugPicture from 'assets/images/HomeBugPicture.png';
import HomeSuggestionsPicture from 'assets/images/HomeSuggestionsPicture.png';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import HomeIBuy from 'assets/images/Buy.svg';
import HomeIVote from 'assets/images/Vote.svg';
import HomeIPlay from 'assets/images/Play.svg';
import ProposeIdea from 'assets/pictograms/ProposeIdea.svg';
import HomeButtonWave from 'assets/images/HomeButtonWave.png';
import { theme } from 'core/theme';
import HomeDjobWave from 'assets/images/HomeDjobWave.png';

const NoAffiliated = () => {
  const [t] = useTranslation();

  return (
    <Box>
      <Paper
        elevation={4}
        sx={{
          backgroundImage: `url(${HomeDjobWave})`,
          backgroundPosition: 'bottom',
          backgroundSize: 'cover',
          borderRadius: '20px',
          backgroundColor: '#F7F7F8',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            paddingTop: '16px',
            background: theme.palette.primary.main,
            borderRadius: '0 0 20px 20px',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              maxWidth: '1200px',
              margin: '0 auto',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: '38px',
                top: '15px',
              }}
            >
              <Avatar />
            </Box>
            <Stack justifyContent="center" alignItems="center">
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ margin: '15px 26px 15px 176px' }}
              >
                <Typography
                  variant="body1"
                  className="text-ellipsis text-ellipsis--2"
                  color={theme.palette.commonPalette.whiteFont}
                  fontWeight="500"
                  fontSize="20px"
                >
                  {t('HOMEPAGE.HELLO')}
                </Typography>
                <Typography variant="body1" color={theme.palette.commonPalette.whiteFont}>
                  {t('HOMEPAGE.GLAD_TO_SEE_YOU')}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Paper>

        <Box
          sx={{
            marginTop: '50px',
            maxWidth: '1200px',
            margin: '24px auto 0 auto',
            padding: '0 16px 0 16px',
          }}
        >
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {t('HOMEPAGE.DJOB_SENTENCE_1')}
            {t('HOMEPAGE.DJOB_BOLD_1')}
            <br />
            <strong style={{ color: theme.palette.text.primary }}>
              {t('HOMEPAGE.DJOB_SENTENCE_2')}
            </strong>
            <br />
            <br />
            <span style={{ marginLeft: '8px' }}>
              1.
              <strong style={{ color: theme.palette.text.primary }}>
                {t('HOMEPAGE.DJOB_BOLD_WIN')}
              </strong>{' '}
            </span>
            {t('HOMEPAGE.DJOB_SENTENCE_WIN')}
            <br />
            <span style={{ marginLeft: '8px' }}>
              2.
              <strong style={{ color: theme.palette.text.primary }}>
                {t('HOMEPAGE.DJOB_BOLD_CONVERT')}
              </strong>{' '}
            </span>
            {t('HOMEPAGE.DJOB_SENTENCE_CONVERT')}
            <br />
            <span style={{ marginLeft: '8px' }}>
              3.
              <strong style={{ color: theme.palette.text.primary }}>
                {t('HOMEPAGE.DJOB_CONVERT')}
              </strong>
            </span>{' '}
            {t('HOMEPAGE.DJOB_GAIN_CUSTOMIZED')}
            <br />
            <br />
            {t('HOMEPAGE.DJOB_VOLUNTARY_ANONYMOUS')}
            <br />
            <br />
            {t('HOMEPAGE.LETS_DJOB')}
          </Typography>
          <Divider
            sx={{
              margin: '20px 0 8px 0',
              height: '2px',
              background: theme.palette.gradient.linearSpecial,
            }}
          />
          <Accordion
            disableGutters
            elevation={0}
            defaultExpanded={false}
            sx={{
              '& .MuiButtonBase-root': {
                padding: 0,
              },
              '&:before': {
                display: 'none',
              },
              backgroundColor: 'transparent',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Stack direction="row" alignItems="center">
                <img src={ProposeIdea} />
                <Typography
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    letterSpacing: '1px',
                  }}
                  variant="body2"
                >
                  {t('HOMEPAGE.I_IMPROVE_APP')}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                gap="16px"
                sx={{ maxWidth: '393px', margin: '0 auto' }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    minWidth: '164px',
                    minHeight: '198px',
                    maxWidth: '184px',
                    maxHeight: '198px',
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: '20px',
                    boxShadow: theme.palette.commonPalette.boxShadow,
                  }}
                >
                  <Stack
                    justifyContent="end"
                    alignItems="center"
                    bgcolor={theme.palette.error.light}
                    sx={{
                      borderRadius: '20px',
                      height: '114px',
                    }}
                  >
                    <img src={HomeBugPicture} />
                  </Stack>
                  <Stack justifyContent="center" sx={{ padding: '0 16px', marginTop: '24px' }}>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ borderRadius: '10px', padding: '8px 0 !important' }}
                    >
                      {t('HOMEPAGE.A_BUG')}
                    </Button>
                  </Stack>
                </Paper>
                <Paper>
                  <Stack
                    justifyContent="end"
                    alignItems="center"
                    bgcolor={theme.palette.primary.main}
                  >
                    <img src={HomeSuggestionsPicture} style={{ borderRadius: '20px' }} />
                  </Stack>
                  <Stack justifyContent="center" sx={{ padding: '0 16px', marginTop: '24px' }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      sx={{ borderRadius: '10px', padding: '6px 0 !important' }}
                    >
                      {t('HOMEPAGE.A_SUGGESTION')}
                    </Button>
                  </Stack>
                </Paper>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>
      <Box sx={{ maxWidth: '1200px', padding: '0 16px', margin: '24px auto 0 auto' }}>
        <Box sx={{ marginTop: '16px' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundImage: `url(${HomeButtonWave})`,
              boxShadow: theme.palette.commonPalette.boxShadow,
            }}
          >
            <Typography variant="subtitle1" color="primary">
              {t('HOMEPAGE.I_WIN_TOKENS')}
            </Typography>
            <Fab
              sx={{
                background:
                  '-webkit-linear-gradient(70.7deg, #3A07BD 7.12%, #532BE3 42.71%, #7858ED 75.64%, #9A7EFE 109.1%)',
                color: theme.palette.commonPalette.whiteFont,
              }}
            >
              <StarBorderOutlinedIcon />
            </Fab>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundImage: `url(${HomeButtonWave})`,
              marginY: '24px',
              boxShadow: theme.palette.commonPalette.boxShadow,
            }}
          >
            <Typography variant="subtitle1" color="primary">
              {t('HOMEPAGE.I_TRANSFER_TOKENS')}
            </Typography>
            <Fab
              sx={{
                background:
                  '-webkit-linear-gradient(70.7deg, #3A07BD 7.12%, #532BE3 42.71%, #7858ED 75.64%, #9A7EFE 109.1%)',
                color: theme.palette.commonPalette.whiteFont,
              }}
            >
              <CompareArrowsIcon />
            </Fab>
          </Stack>
        </Box>
        <Typography variant="h6" sx={{ marginTop: '24px' }}>
          📅 {t('HOMEPAGE.BY_DECEMBER')}
        </Typography>
        <Typography variant="body2" mt="8px">
          {t('HOMEPAGE.DJOB_SENTENCE_5')} <br />
          {t('HOMEPAGE.DJOB_SENTENCE_6')} <br />
          {t('HOMEPAGE.DJOB_SENTENCE_7')}
        </Typography>
        <Stack sx={{ marginTop: '24px', width: '100%' }} alignItems="center">
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100%', maxWidth: '368px' }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: '12px',
                minWidth: '144px',
                maxWidth: '176px',
                minHeight: '208px',
                width: '100%',
                backgroundImage: `url(${HomeIBuy})`,
                objectFit: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
                backgroundSize: '100%',
              }}
            >
              <Typography>{t('HOMEPAGE.I_BUY')}</Typography>
            </Box>
            <Box
              sx={{
                marginLeft: 1.6,
                backgroundColor: theme.palette.background.paper,
                borderRadius: '12px',
                minWidth: '144px',
                maxWidth: '176px',
                minHeight: '208px',
                width: '100%',
                backgroundImage: `url(${HomeIVote})`,
                objectFit: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
                backgroundSize: '70%',
              }}
            >
              <TitleTypography>{t('HOMEPAGE.I_VOTE')}</TitleTypography>
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: theme.palette.background.paper,
              marginY: 1.6,
              minHeight: '150px',
              maxWidth: '368px',
              width: '100%',
              borderRadius: '12px',
            }}
          >
            <Box>
              <Typography sx={{ paddingBottom: '7px' }}>{t('HOMEPAGE.I_PLAY')}</Typography>
              <ul
                style={{
                  textAlign: 'center',
                  listStylePosition: 'inside',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  padding: '0 0 15px 16px',
                  marginLeft: 10,
                }}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography color="primary">&#8226;</Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      background:
                        '-webkit-linear-gradient(68.21deg, #5120CB 11.28%, #532BE3 27.6%, #FF5471 75.25%, #F5279C 89.02%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    {t('HOMEPAGE.LOTERY')}
                  </Typography>
                </Stack>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography color="primary">&#8226;</Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      background:
                        '-webkit-linear-gradient(68.21deg, #5120CB 11.28%, #532BE3 27.6%, #FF5471 75.25%, #F5279C 89.02%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    {t('HOMEPAGE.CONTEST')}
                  </Typography>
                </Stack>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography color="primary">&#8226;</Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      background:
                        '-webkit-linear-gradient(68.21deg, #5120CB 11.28%, #532BE3 27.6%, #FF5471 75.25%, #F5279C 89.02%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    {t('HOMEPAGE.BET')}
                  </Typography>
                </Stack>
              </ul>
            </Box>
            <img src={HomeIPlay} style={{ padding: '5px 25px 4px' }} />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default NoAffiliated;
