import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatusEnum } from '../core/enums/stateEnum';
import { IResponseError } from '../core/interfaces/status';
import { RootState } from './rootReducer';
import {
  IInitiative,
  IInitiativePayload,
  IInitiativeResponse,
} from '../core/interfaces/initiative';
import request from 'axios';
import i18next from 'i18next';
import { setSnackBar } from './snackbarSlice';
import { createInitiative } from '../services/initiativeApi';
import { initiativeInitialState } from '../core/constants/initiative';
import {
  fetchInitiativeCandidate,
  patchInitiativeCandidate,
  validateInitiativeCandidate,
} from '../services/initiativeCandidateApi';
import { TypeInitiativeEnum } from '../core/enums/initiativeEnum';

interface IInitiativeState {
  initiative: IInitiative;
  step: number;
  validBalance: boolean;
  loading: RequestStatusEnum;
  error: IResponseError;
}

const initialState: IInitiativeState = {
  initiative: initiativeInitialState,
  step: 0,
  validBalance: false,
  loading: RequestStatusEnum.IDLE,
  error: {} as IResponseError,
};

export const fetchInitiativeCandidateThunk = createAsyncThunk(
  'initiative/fetchInitiativeCandidateThunk',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchInitiativeCandidate(id);
      return response.data as IInitiativeResponse;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const createInitiativeThunk = createAsyncThunk(
  'initiative/createInitiativeThunk',
  async (typology: TypeInitiativeEnum, { rejectWithValue, dispatch }) => {
    try {
      const response = await createInitiative(typology);
      return response.data as IInitiativeResponse;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const patchInitiativeCandidateThunk = createAsyncThunk(
  'initiative/patchInitiativeCandidateThunk',
  async (data: IInitiativePayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await patchInitiativeCandidate(data);
      return response.data as IInitiativeResponse;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const validateInitiativeCandidateThunk = createAsyncThunk(
  'initiative/validateInitiativeCandidateThunk',
  async (
    data: { initiativeId: number; initiative: IInitiative; step: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await validateInitiativeCandidate(data);
      return response.data as IInitiativeResponse;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);
const initiativeSlice = createSlice({
  name: 'initiative',
  initialState,
  reducers: {
    setCurrentStep: (state: IInitiativeState, action: { payload: number }) => {
      state.step = action.payload;
    },
    setValidBalance: (state: IInitiativeState, action: { payload: boolean }) => {
      state.validBalance = action.payload;
    },
    resetInitiative: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInitiativeCandidateThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(fetchInitiativeCandidateThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.initiative = {
        ...action?.payload?.initiative,
      } as IInitiative;
      state.step = action?.payload?.step as number;
      return state;
    });
    builder.addCase(fetchInitiativeCandidateThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
    builder.addCase(createInitiativeThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(createInitiativeThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.initiative = {
        ...action?.payload?.initiative,
      } as IInitiative;
      return state;
    });
    builder.addCase(createInitiativeThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
    builder.addCase(patchInitiativeCandidateThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(patchInitiativeCandidateThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.initiative = {
        ...action?.payload?.initiative,
      } as IInitiative;
      return state;
    });
    builder.addCase(patchInitiativeCandidateThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
    builder.addCase(validateInitiativeCandidateThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(validateInitiativeCandidateThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.initiative = {
        ...action?.payload?.initiative,
      } as IInitiative;
      return state;
    });
    builder.addCase(validateInitiativeCandidateThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
  },
});

export const { setCurrentStep, resetInitiative, setValidBalance } = initiativeSlice.actions;

export const selectInitiative = (state: RootState) => state.initiativeReducer;

export default initiativeSlice.reducer;
