import { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Dialog, Stack, TextField, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import GirlSorry from 'assets/images/GirlSorry.png';
import { theme } from 'core/theme';
import { submitErrorMessage } from 'services/teamsErrorApi';
import { useLocation } from 'react-router-dom';
import { IResponseErrorForbidden } from 'core/interfaces/status';

interface IErrorPayload {
  firstName: string | undefined;
  lastName: string | undefined;
  company?: string;
  reason?: string;
  managerEmail: string;
  email?: string;
  message: string;
}

const ErrorDeniedPage = () => {
  const location = useLocation();
  const errorData: IResponseErrorForbidden = location.state?.errorData;

  const [t] = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showError, setShowError] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean | null>(null);
  const [payload, setPayload] = useState<IErrorPayload>({
    firstName: errorData ? errorData.firstName : '',
    lastName: errorData ? errorData.lastName : '',
    email: errorData ? errorData.email : '',
    managerEmail: '',
    message: '',
    company: '',
    reason: "Demande d'activation",
  });

  const validateEmail = (managerEmail: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(managerEmail);
  };
  const isDisabled = !validateEmail(payload.managerEmail);
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const managerEmail = e.target.value;
    setPayload((prev) => ({ ...prev, managerEmail }));
    setShowError(!validateEmail(managerEmail));
  };
  const requestSupport = async () => {
    try {
      await submitErrorMessage(payload);
      setIsSubmitSuccess(true);
    } catch (error) {
      setIsSubmitSuccess(false);
      console.log(error);
    }
  };
  return !isMobile ? (
    <Dialog
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: theme.palette.purple.backdropPurple,
          backdropFilter: 'blur(4px)',
        },
        '& .MuiDialogContent-root': { padding: '0' },
        '& .MuiDialog-paper': {
          borderRadius: '8px',
        },
      }}
      open={true}
    >
      <Stack
        sx={{
          width: '360px',
          maxHeight: '725px',
          borderRadius: '8px',
          backgroundColor: theme.palette.purple.lightPurple,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={GirlSorry}
          style={{ padding: '5px 0px 5px 0px', width: '205px', height: '138px', marginTop: '12px' }}
        />
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: '800',
            lineHeight: '28px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: theme.palette.text.secondary,
          }}
        >
          {t('RECOGNIZE_ACTIONS.RECOGNITION_TITLES_ERROR')}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '18px',
            letterSpacing: '0em',
            textAlign: 'center',
            padding: '16px',
            color: theme.palette.text.secondary,
          }}
        >
          <Trans>{t('INTEGRATION.TEAMS.REVOKED_STATUS')}</Trans>
        </Typography>

        <Box
          sx={{
            width: '340px',
            marginBottom: '12px',
            borderRadius: '8px',
            backgroundColor: theme.palette.commonPalette.whiteFont,
            padding: '16px', // Add some padding for spacing
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px', // Gap between fields
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: theme.palette.text.secondary,
            }}
          >
            {t('INTEGRATION.TEAMS.REVOKED_MESSAGE')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', width: '100%' }}>
            <TextField
              label={t('INTEGRATION.TEAMS.LAST_NAME') + '*'}
              placeholder={t('INTEGRATION.TEAMS.LAST_NAME')}
              variant="outlined"
              size="medium"
              InputLabelProps={{ style: { fontSize: '12px' } }}
              disabled={errorData ? true : false}
              value={errorData ? errorData.lastName : undefined}
              onChange={(e) => {
                setPayload((prev) => ({ ...prev, lastName: e.target.value }));
              }}
            />
            <TextField
              label={t('INTEGRATION.TEAMS.FIRST_NAME') + '*'}
              placeholder={t('INTEGRATION.TEAMS.FIRST_NAME')}
              variant="outlined"
              InputLabelProps={{ style: { fontSize: '12px' } }}
              size="medium"
              disabled={errorData ? true : false}
              value={errorData ? errorData.firstName : undefined}
              onChange={(e) => {
                setPayload((prev) => ({ ...prev, firstName: e.target.value }));
              }}
            />
          </Box>
          <TextField
            label={t('INTEGRATION.TEAMS.MANAGER_EMAIL') + '*'}
            placeholder={t('INTEGRATION.TEAMS.MANAGER_EMAIL')}
            variant="outlined"
            size="medium"
            InputLabelProps={{ style: { fontSize: '12px' } }}
            value={payload.managerEmail}
            sx={{ width: '100%' }}
            error={showError}
            helperText={showError ? t('INTEGRATION.TEAMS.INVALID_EMAIL') : ''}
            onChange={handleEmailChange}
            onFocus={() => setShowError(true)}
            onBlur={() => setShowError(false)}
          />
          <TextField
            label={t('INTEGRATION.TEAMS.DISCOVER_US')}
            variant="outlined"
            size="medium"
            placeholder={t('INTEGRATION.TEAMS.DISCOVER_US')}
            InputLabelProps={{ style: { fontSize: '12px' } }}
            sx={{ width: '100%' }}
            multiline
            onChange={(e) => {
              setPayload((prev) => ({ ...prev, message: e.target.value }));
            }}
            rows={3}
          />
          <Button
            onClick={requestSupport}
            disabled={isDisabled}
            sx={{
              width: '192px',
              height: '36px',
              borderRadius: '12px',
              backgroundColor: isDisabled
                ? theme.palette.primary.light
                : theme.palette.primary.main,
              color: theme.palette.commonPalette.whiteFont,
              ':hover': {
                backgroundColor: isDisabled
                  ? theme.palette.primary.light
                  : theme.palette.primary.dark,
                color: theme.palette.commonPalette.whiteFont,
              },
            }}
          >
            {t('INTEGRATION.TEAMS.ENVOYER')}
          </Button>
          {isSubmitSuccess !== null && (
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '18px',
                letterSpacing: '0em',
                textAlign: 'center',
                color: isSubmitSuccess ? theme.palette.success.dark : theme.palette.error.dark,
              }}
            >
              {isSubmitSuccess
                ? t('INTEGRATION.TEAMS.SUBMIT_SUCCESS')
                : t('INTEGRATION.TEAMS.SUBMIT_FAIL')}
            </Typography>
          )}
        </Box>
      </Stack>
    </Dialog>
  ) : (
    <Stack
      sx={{
        width: '100vw',
        maxHeight: '725px',
        borderRadius: '8px',
        backgroundColor: theme.palette.purple.lightPurple,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src={GirlSorry}
        style={{ padding: '5px 0px 5px 0px', width: '205px', height: '138px', marginTop: '12px' }}
      />
      <Typography
        sx={{
          fontSize: '22px',
          fontWeight: '800',
          lineHeight: '28px',
          letterSpacing: '0em',
          textAlign: 'center',
          color: theme.palette.text.secondary,
        }}
      >
        {t('RECOGNIZE_ACTIONS.RECOGNITION_TITLES_ERROR')}
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '18px',
          letterSpacing: '0em',
          textAlign: 'center',
          padding: '16px',
          color: theme.palette.text.secondary,
        }}
      >
        <Trans>{t('INTEGRATION.TEAMS.REVOKED_STATUS')}</Trans>
      </Typography>

      <Box
        sx={{
          width: '340px',
          marginBottom: '12px',
          borderRadius: '8px',
          backgroundColor: theme.palette.commonPalette.whiteFont,
          padding: '16px', // Add some padding for spacing
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px', // Gap between fields
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '18px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: theme.palette.text.secondary,
          }}
        >
          {t('INTEGRATION.TEAMS.REVOKED_MESSAGE')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', width: '100%' }}>
          <TextField
            label={t('INTEGRATION.TEAMS.LAST_NAME') + '*'}
            placeholder={t('INTEGRATION.TEAMS.LAST_NAME')}
            variant="outlined"
            size="medium"
            InputLabelProps={{ style: { fontSize: '12px' } }}
            disabled={errorData ? true : false}
            value={errorData ? errorData.lastName : undefined}
            onChange={(e) => {
              setPayload((prev) => ({ ...prev, lastName: e.target.value }));
            }}
          />
          <TextField
            label={t('INTEGRATION.TEAMS.FIRST_NAME') + '*'}
            placeholder={t('INTEGRATION.TEAMS.FIRST_NAME')}
            variant="outlined"
            InputLabelProps={{ style: { fontSize: '12px' } }}
            size="medium"
            disabled={errorData ? true : false}
            value={errorData ? errorData.firstName : undefined}
            onChange={(e) => {
              setPayload((prev) => ({ ...prev, firstName: e.target.value }));
            }}
          />
        </Box>
        <TextField
          label={t('INTEGRATION.TEAMS.MANAGER_EMAIL') + '*'}
          placeholder={t('INTEGRATION.TEAMS.MANAGER_EMAIL')}
          variant="outlined"
          size="medium"
          InputLabelProps={{ style: { fontSize: '12px' } }}
          value={payload.managerEmail}
          sx={{ width: '100%' }}
          error={showError}
          helperText={showError ? t('INTEGRATION.TEAMS.INVALID_EMAIL') : ''}
          onChange={handleEmailChange}
          onFocus={() => setShowError(true)}
          onBlur={() => setShowError(false)}
        />
        <TextField
          label={t('INTEGRATION.TEAMS.DISCOVER_US')}
          variant="outlined"
          size="medium"
          placeholder={t('INTEGRATION.TEAMS.DISCOVER_US')}
          InputLabelProps={{ style: { fontSize: '12px' } }}
          sx={{ width: '100%' }}
          multiline
          onChange={(e) => {
            setPayload((prev) => ({ ...prev, message: e.target.value }));
          }}
          rows={3}
        />
        <Button
          onClick={requestSupport}
          disabled={isDisabled}
          sx={{
            width: '192px',
            height: '36px',
            borderRadius: '12px',
            backgroundColor: isDisabled ? theme.palette.primary.light : theme.palette.primary.main,
            color: theme.palette.commonPalette.whiteFont,
            ':hover': {
              backgroundColor: isDisabled
                ? theme.palette.primary.light
                : theme.palette.primary.dark,
              color: theme.palette.commonPalette.whiteFont,
            },
          }}
        >
          {t('INTEGRATION.TEAMS.ENVOYER')}
        </Button>
        {isSubmitSuccess !== null && (
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: isSubmitSuccess ? theme.palette.success.dark : theme.palette.error.dark,
            }}
          >
            {isSubmitSuccess
              ? t('INTEGRATION.TEAMS.SUBMIT_SUCCESS')
              : t('INTEGRATION.TEAMS.SUBMIT_FAIL')}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

export default ErrorDeniedPage;
