import { Auth0Provider } from '@auth0/auth0-react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';

import store from './state/store';

import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import Teams from './Teams';
import { configI18n } from './i18nConfig';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import pkg from '../package.json';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: `${process.env.NODE_ENV}-${document.location.host}`,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
    release: pkg.version,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      'https://dev.app.djob.io/',
      'https://stage.app.djob.io/',
      'https://app.djob.io/',
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

/**
 * Polyfill for Array.prototype.at
 * wich is not supported in some old versions of Safari.
 */
if (!Array.prototype.at) {
  Array.prototype.at = function (index) {
    index = Math.trunc(index) || 0;
    if (index < 0) index += this.length;
    if (index < 0) index = 0;
    if (index >= this.length) index = this.length - 1;
    return this[index];
  };
}

function IsTeamsTab() {
  if (window) {
    if (window.name === 'embedded-page-container' || window.name === 'extension-tab-frame') {
      return true;
    }
    const regex = new RegExp('TeamsMobile-iOS|TeamsMobile-Android', 'gi');
    return regex.test(window.navigator.userAgent);
  }
  return false;
}
//Auth0
function getAuth0Information(): { domain: string; clientId: string; url: string } {
  const originUrl = window.location.href;
  if ((/djob.io/).test(originUrl)) {
    return {
      domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
      url: process.env.REACT_APP_AUTH0_URL as string
    }
  }
  return {
    domain: process.env.REACT_APP_AUTH0_DOMAIN_CULTURE_LIVE as string,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID_CULTURE_LIVE as string,
    url: process.env.REACT_APP_AUTH0_URL_CULTURE_LIVE as string
  }
}
const auth0Information = getAuth0Information();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

i18next.init(configI18n);

root.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        {IsTeamsTab() ? (
          <Teams />
        ) : (
          <Auth0Provider
            domain={auth0Information.url}
            clientId={auth0Information.clientId}
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: `https://${auth0Information.domain}/api/v2/`
            }}
          >
            <App />
          </Auth0Provider>
        )}
      </Provider>
    </I18nextProvider>
  </BrowserRouter>
);
