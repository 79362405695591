import { addResourceBundle } from 'i18nConfig';
import axios from 'axios';
import { IResponseErrorForbidden } from 'core/interfaces/status';
import { useEffect, useState } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { getAccount } from 'services/accountApi';
interface ITeamsProtectedRouteProps extends Omit<RouteProps, 'component'> {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  children?: any;
}

/* Account deleted => 5001
Account not validated => 5002
Account not exists => 5003
Company not exists => 5004 */

const TeamsProtectedRoute: React.FC<ITeamsProtectedRouteProps> = ({ children }) => {
  const [codeError, setCodeError] = useState<number | null>(null);
  const [errorData, setErrorData] = useState<IResponseErrorForbidden | null>(null);
  useEffect(() => {
    (async () => {
      try {
        await getAccount();
      } catch (error) {
        addResourceBundle('en');
        if (axios.isAxiosError(error) && error.response) {
          const responseData: IResponseErrorForbidden = error.response
            .data as IResponseErrorForbidden;
          setErrorData(responseData);
          if (responseData.code === 5001) {
            setCodeError(5001);
          }
          if (responseData.code === 5002) {
            setCodeError(5002);
          }
          if (responseData.code === 5003) {
            setCodeError(5003);
          }
          if (responseData.code === 5004) {
            setCodeError(5004);
          }
        }
      }
      //remove when after test
      console.log('codeError', codeError);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (codeError) {
    if (codeError === 5001 || codeError === 5002) {
      return <Navigate to="/revoked" replace state={{ errorData: errorData }} />;
    }
    if (codeError === 5003) {
      return <Navigate to="/denied" replace state={{ errorData: errorData }} />;
    }
    if (codeError === 5004) {
      return <Navigate to="/error-company-not-known" replace state={{ errorData: errorData }} />;
    }
  }

  return children;
};

export default TeamsProtectedRoute;
