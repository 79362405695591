import { queryBuilder } from '../core/helpers/apiHelpers';
import axiosInstance from '../core/interceptors/authInterceptor';
import { IAccountOptions } from '../core/interfaces/accountApi';
import { IBalanceOptions } from '../core/interfaces/serviceApi';
import { ICreateTokenInputForm, IPatchTokenAdminPayload } from '../core/interfaces/token';

const fetchTokenCollection = async (options: IBalanceOptions) => {
  const queryParams = queryBuilder(options?.queryParams);

  return await axiosInstance.get(`/balances${queryParams}`);
};

const fetchTokenCompanyCollection = async (options: IBalanceOptions) => {
  const queryParams = queryBuilder(options?.queryParams);

  return await axiosInstance.post(`/balances/company/${options.companyId}${queryParams}`, {
    typologies: options.typologies,
    categories: options.categories,
    ...(options.isTransferableInternal && {
      isTransferableInternal: options.isTransferableInternal,
    }),
  });
};

const fetchCategories = async () => {
  const response = await axiosInstance.get('/token/categories');
  return response.data;
};

const fetchCategoriesByBadge = async (companyId: number) => {
  const response = await axiosInstance.get(`/token/badge/categories/${companyId}`);
  return response.data;
};

const fetchTokenById = async (tokenId: string | undefined) => {
  return await axiosInstance.get(`/balance/${tokenId}`);
};

const fetchTokenSupplierById = async (tokenId: string | undefined) => {
  return await axiosInstance.get(`/token/${tokenId}`);
};

const fetchAllTokens = async (options: IBalanceOptions) => {
  const queryParams = queryBuilder(options?.queryParams);

  return await axiosInstance.get(`/balances${queryParams}`);
};

const mintToken = async (tokenId: string, quantity: number) => {
  return await axiosInstance.post(`/token/mint/${tokenId}`, {
    quantity,
  });
};

const createToken = async (data: ICreateTokenInputForm, companyId: number) => {
  return await axiosInstance.post(`/token`, {
    companyId,
    categoryId: data.categoryId,
    tokenType: data.tokenType,
    name: data.name,
    description: data.description,
    isTransferableInternal: data.isTransferableInternal,
    isTransferableExternal: data.isTransferableExternal,
    existOnChain: data.existOnChain,
    initialSupply: data.maxSupply,
    toMint: data.nbTokens,
    picture: data.picture,
    ...(data.stepLevels && { stepLevels: data.stepLevels }),
    ...(data.stepLevel && { stepLevel: data.stepLevel }),
  });
};

const fetchBadges = async (companyId: number, categoryId: number) => {
  const response = await axiosInstance.get(`/token/badge/${categoryId}/${companyId}`);
  return response.data;
};

const fetchBalanceAutocomplete = async (options: IAccountOptions, typologies: string[] = []) => {
  const queryParams = queryBuilder(options?.queryParams);

  return await axiosInstance.post(`/balances/minify/company/${options.companyId}/${queryParams}`, {
    query: options.query,
    typologies,
  });
};

const fetchSpendingToken = async () => {
  return await axiosInstance.get(`/balances/current`);
};

const fetchSpendingTokenByCompany = async (companyId: number) => {
  return await axiosInstance.get(`/token/spending/${companyId}`);
};

const patchToken = async (tokenId: string, data: IPatchTokenAdminPayload) => {
  return await axiosInstance.patch(`/token/${tokenId}`, data);
};

export {
  createToken,
  fetchAllTokens,
  fetchBadges,
  fetchBalanceAutocomplete,
  fetchCategories,
  fetchCategoriesByBadge,
  fetchSpendingToken,
  fetchSpendingTokenByCompany,
  fetchTokenById,
  fetchTokenCollection,
  fetchTokenCompanyCollection,
  fetchTokenSupplierById,
  mintToken,
  patchToken,
};
