export function getAuth0Information(): { domain: string; clientId: string; url: string } {
  const originUrl = window.location.href;
  if (/djob.io/.test(originUrl)) {
    return {
      domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
      url: process.env.REACT_APP_AUTH0_URL as string,
    };
  }
  return {
    domain: process.env.REACT_APP_AUTH0_DOMAIN_CULTURE_LIVE as string,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID_CULTURE_LIVE as string,
    url: process.env.REACT_APP_AUTH0_URL_CULTURE_LIVE as string,
  };
}

export const auth0TokenOptions = {
  audience: `https://${getAuth0Information().domain}/api/v2/`,
  scope: 'read:current_user',
  detailedResponse: true,
};
