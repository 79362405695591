import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IMe } from 'core/interfaces/account';
import { RequestStatusEnum } from 'core/enums/stateEnum';
import { getAccount, patchAccount } from 'services/accountApi';
import { IResponseError } from 'core/interfaces/status';
import { RootState } from './rootReducer';
import request from 'axios';
import { setSnackBar } from './snackbarSlice';
import i18next from 'i18next';
import { LANGUAGES, NotificationsTypeEnum } from 'core/enums/accountEnum';
import { ISpendingToken } from 'core/interfaces/token';
import { fetchSpendingToken } from 'services/tokenApi';
import { fetchSpendingTokenByType } from 'services/companyApi';

interface IAccountState {
  account: IMe;
  spendingToken: ISpendingToken | null;
  spendingTokenCharitips: ISpendingToken | null;
  loading: RequestStatusEnum;
  error: IResponseError | null;
  updatingStatus: RequestStatusEnum;
}

export const initialState: IAccountState = {
  account: {
    accountId: '',
    firstName: '',
    lastName: '',
    email: '',
    picture: '',
    language: i18next.language as LANGUAGES,
    phonePrefix: '',
    phoneValue: '',
    pages: [],
    company: {
      companyId: null,
      name: '',
    },
    role: null,
    permissions: [],
    modules: [],
    notifications: [
      {
        type: NotificationsTypeEnum.MAIL,
        active: false,
      },
      {
        type: NotificationsTypeEnum.SLACK,
        active: false,
      },
      {
        type: NotificationsTypeEnum.TEAMS,
        active: false,
      },
      {
        type: NotificationsTypeEnum.GOOGLE,
        active: false,
      },
    ],
    activated: false,
    isFromTeams: true,
    selected: false,
    approved: false,
    revoked: false,
    groupId: '',
    webAccess: false,
    lastConnectedAt: null,
    recognitionTypes: [],
  },
  spendingToken: null,
  spendingTokenCharitips: null,
  loading: RequestStatusEnum.IDLE,
  error: null,
  updatingStatus: RequestStatusEnum.IDLE,
};

export const fetchAccountThunk = createAsyncThunk(
  'account/fetchAccountThunk',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAccount();
      i18next.changeLanguage(response.data.language);
      return response.data as IMe;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);
export const fetchAccountSpendingThunk = createAsyncThunk(
  'account/fetchAccountSpendingThunk',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchSpendingToken();
      return response.data as ISpendingToken;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const fetchSpendingTokenByTypeThunk = createAsyncThunk(
  'account/fetchSpendingTokenByTypeThunk',
  async (type: 'association' | 'giftCard', { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchSpendingTokenByType(type);
      return response.data as ISpendingToken;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

export const patchSpecificPropertiesProfileThunk = createAsyncThunk(
  'account/patchSpecificPropertiesProfileThunk',
  async (data: Partial<IMe>, { rejectWithValue, dispatch }) => {
    try {
      const response = await patchAccount(data);
      if (Object.prototype.hasOwnProperty.call(data, 'pages')) {
        return response.data as IMe;
      }
      dispatch(
        setSnackBar({
          open: true,
          type: 'success',
          message: i18next.t('EDIT_PROFILE.SUCCESS_UPDATE_PROFILE'),
        })
      );
      return response.data as IMe;
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { data } = error.response;
        dispatch(
          setSnackBar({
            open: true,
            type: 'error',
            message: i18next.t('COMMON.SNACKBAR_MESSAGE.ERROR'),
          })
        );
        return rejectWithValue(data as IResponseError);
      }
    }
  }
);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateAccount: (state: IAccountState, { payload }: PayloadAction<IMe>) => {
      state.account = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAccountThunk.pending, (state) => {
      state.loading = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(fetchAccountThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.account = { ...action.payload, isFromTeams: false } as IMe;
      return state;
    });
    builder.addCase(fetchAccountThunk.rejected, (state, error) => {
      state.loading = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
    builder.addCase(fetchAccountSpendingThunk.fulfilled, (state, action) => {
      state.spendingToken = action.payload as ISpendingToken;
      return state;
    });
    builder.addCase(fetchAccountSpendingThunk.rejected, (state) => {
      state.spendingToken = null;
      return state;
    });
    builder.addCase(patchSpecificPropertiesProfileThunk.pending, (state) => {
      state.updatingStatus = RequestStatusEnum.PENDING;
      return state;
    });
    builder.addCase(patchSpecificPropertiesProfileThunk.fulfilled, (state, action) => {
      state.updatingStatus = RequestStatusEnum.FULFILLED;
      state.account = { ...state.account, ...action.payload } as IMe;
    });
    builder.addCase(patchSpecificPropertiesProfileThunk.rejected, (state, error) => {
      state.updatingStatus = RequestStatusEnum.REJECTED;
      state.error = error.payload as IResponseError;
      return state;
    });
    builder.addCase(fetchSpendingTokenByTypeThunk.fulfilled, (state, action) => {
      state.loading = RequestStatusEnum.FULFILLED;
      state.spendingTokenCharitips = action.payload as ISpendingToken;
      return state;
    });
    builder.addCase(fetchSpendingTokenByTypeThunk.rejected, (state) => {
      state.spendingTokenCharitips = null;
      return state;
    });
  },
});
export const { updateAccount } = accountSlice.actions;
export const selectAccount = (state: RootState) => state.accountReducer;

export default accountSlice.reducer;
